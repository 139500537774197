import React, { useEffect, useRef, useState } from "react";
import { Image, InputGroup, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import Pusher from "pusher-js";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { useDispatch, useSelector } from "react-redux";
import {
  chatSupportMessageReadStart,
  chatSupportMessageStart,
  chatSupportMessagesStart,
  setActiveChatSupport,
} from "../../store/slices/PayoutSlice";
import PageLoader from "../Helper/PageLoader";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import TimeElapsed from "./TimeElapsed";

const ChatWidget = (props) => {
  const t = useTranslation("deposit.chat_widget");
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);
  const [messages, setMessages] = useState();
  const [messageList, setMessageList] = useState([
    // { sent_by: "support_member", message: "Hello there how are u today?" },
    // { sent_by: "you", message: "I'm fine, how about u?" },
  ]);
  const [inputData, setInputData] = useState({});
  const [fileName, setFileName] = useState("");
  const [previewImage, setPreviewImage] = useState();
  const messageField = useRef();
  const lastMsg = useRef();
  const chatSupportMessage = useSelector(
    (state) => state.payout.chatSupportMessage
  );
  const chatSupportMessageRead = useSelector(
    (state) => state.payout.chatSupportMessageRead
  );
  const chatSupportMessages = useSelector(
    (state) => state.payout.chatSupportMessages
  );
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    // console.log(props.show)
    dispatch(
      chatSupportMessagesStart({
        whitelabel_merchant_deposit_id: props.header
          ? props.show.id
          : props.show.payout_deposit_id,
      })
    );
    const pusher = new Pusher("3762e4d68b0ec842f82c", {
      cluster: "ap2",
      encrypted: true,
    });
    const channel = pusher.subscribe(
      `WhitelabelMerchant.${profile.data.user_id}`
    );
    channel.bind("message.received", function (data) {
      data.payload.id == props.show.id && dispatch(
        chatSupportMessageReadStart({
          whitelabel_merchant_deposit_id: props.header
            ? props.show.id
            : props.show.payout_deposit_id,
          message: data.payload.message,
          attachment: data.payload.attachment,
        })
      );
    });
    return () => {
      dispatch(setActiveChatSupport({}));
      pusher.unsubscribe(`WhitelabelMerchant.${profile.data.user_id}`);
    };
  }, []);

  const handleSubmit = (e) => {
    // console.log(message)
    e.preventDefault();
    (message && message.trim().length != 0)
      ? dispatch(
          chatSupportMessageStart({
            whitelabel_merchant_deposit_id: props.header
              ? props.show.id
              : props.show.payout_deposit_id,
            message: message,
          })
        )
      : getErrorNotificationMessage("Please type a message or upload a file.");
  };

  const handleChange = (event) => {
    if (event.target.type === "file") {
      let reader = new FileReader();
      let file = event.target.files[0];
      setFileName(file.name);
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        dispatch(
          chatSupportMessageStart({
            whitelabel_merchant_deposit_id: props.header
              ? props.show.id
              : props.show.payout_deposit_id,
            attachment: event.target.files[0],
          })
        );
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    messages && setMessageList([...messageList, messages]);
    handleScrollDown();
  }, [messages]);

  const [message, setMessage] = useState("");

  const handleMessageSubmit = (e) => {
    e.preventDefault();
    setMessage("");
  };

  const handleScrollDown = () => {
    lastMsg?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    // setScrollDown({
    //   ...scrollDown,
    //   notify: false,
    //   msg_count: 0,
    //   is_at_bottom: true,
    // });
  };

  useEffect(() => {
    if (
      !skipRender &&
      !chatSupportMessage.loading &&
      Object.keys(chatSupportMessage.data).length > 0
    ) {
      setMessages({
        sent_by: "you",
        message: message,
        attachment: chatSupportMessage.data.attachment || "",
        sent_at: new Date().toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true
        }),
      });
      setMessage("");
    }
    setSkipRender(false);
  }, [chatSupportMessage]);

  useEffect(() => {
    if (
      !skipRender &&
      !chatSupportMessageRead.loading &&
      Object.keys(chatSupportMessageRead.data).length > 0
    ) {
      let data = chatSupportMessageRead.data;
      let time = new Date().toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
      })
      setMessages(
        data.attachment
          ? data.message
            ? { sent_by: "admin", file: data.attachment, message: data.message, sent_at: time }
            : { sent_by: "admin", file: data.attachment, sent_at: time }
          : { sent_by: "admin", message: data.message, sent_at: time }
      );
    }
    setSkipRender(false);
  }, [chatSupportMessageRead]);

  useEffect(() => {
    if (
      !skipRender &&
      !chatSupportMessages.loading &&
      Object.keys(chatSupportMessages.data).length > 0
    ) {
      if (chatSupportMessages.data.chat_messages.length > 0) {
        setMessageList(chatSupportMessages.data.chat_messages);
      }
    }
    setSkipRender(false);
  }, [chatSupportMessages]);

  useEffect(() => {
    handleScrollDown();
  }, [messageList]);

  return (
    <div className="client-chat-widget-fixed-sec">
      <div
        className="client-chat-widget-fixed-overlay"
        onClick={() => props.onHide()}
      ></div>
      <div className="client-chat-widget-fixed-card">
        <div className="client-chat-resizable-card-left-header-sec">
          <h5>
            Chat Support (
            {props.header ? props.show.unique_id : props.show.order_id})
          </h5>
          <Button
            className="client-chat-resizable-card-close-btn"
            onClick={() => props.onHide()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#fff"
              viewBox="0 0 24 24"
            >
              <path d="M18 6a1 1 0 00-1.414 0L12 10.586 7.414 6A1 1 0 006 6a1 1 0 000 1.414L10.586 12 6 16.586A1 1 0 006 18a1 1 0 001.414 0L12 13.414 16.586 18A1 1 0 0018 18a1 1 0 000-1.414L13.414 12 18 7.414A1 1 0 0018 6z"></path>
            </svg>
          </Button>
        </div>
        <div className="client-chat-resizable-card-left-body-sec">
          {/* <div className="client-chat-sticky-date-time">
            Monday, February 27 2023, 21:48
          </div> */}
          {chatSupportMessages.loading ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Image
                className="page-loader-img"
                src={window.location.origin + "/img/page-loader.gif"}
              />
            </div>
          ) : messageList.length > 0 ? (
            <React.Fragment>
              {messageList.map((message, key) =>
                message.sent_by == "you" ? (
                  <div className="client-chat-user-sender-card" key={key}>
                    <div className="client-chat-user-sender-icon-sec">
                      <CustomLazyLoad
                        className="client-chat-resizable-ip-icon"
                        src={
                          profile.data.picture ||
                          window.location.origin + "/img/user-img.svg"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="client-chat-user-sender-message-card">
                      <div className="client-chat-user-sender-message">
                        {message.attachment ? (
                          <div className="client-chat-user-sender-message-image">
                            <Image
                              style={{ maxWidth: "12em" }}
                              src={message.attachment}
                            />
                            {message.message}
                          </div>
                        ) : (
                          message.message
                        )}
                      </div>
                      <div className="client-caht-user-sender-time">
                        <TimeElapsed timestamp={message.sent_at} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="client-chat-user-receiver-card" key={key}>
                    <div className="client-chat-user-receiver-icon-sec">
                      <CustomLazyLoad
                        className="client-chat-resizable-ip-icon"
                        src={window.location.origin + "/img/user-img.png"}
                        type="image/png"
                      />
                    </div>
                    <div className="client-chat-user-receiver-message-card">
                      <div className="client-chat-user-receiver-message">
                        {message.attachment ? (
                          <div className="client-chat-user-sender-message-image">
                            <Image
                              style={{ maxWidth: "12em" }}
                              src={message.attachment}
                            />
                            {message.message}
                          </div>
                        ) : (
                          message.message
                        )}
                      </div>
                      <div className="client-caht-user-receiver-time">
                        <TimeElapsed timestamp={message.sent_at} />
                      </div>
                    </div>
                  </div>
                )
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div
                className="broadcast-img-sec mt-5"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1em",
                }}
              >
                <Image
                  className="broadcast-img"
                  src={window.location.origin + "/img/no-chat.png"}
                />
                <h5 style={{ color: "var(--secondary-color)" }}>No Chats</h5>
              </div>
            </React.Fragment>
          )}
          <div className="mt-5" ref={lastMsg} />
          <div className="client-chat-resizable-card-left-footer-form">
            {(chatSupportMessage.buttonDisable || chatSupportMessages.buttonDisable) ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image
                  style={{ maxWidth: "2.4em" }}
                  src={window.location.origin + "/img/page-loader.gif"}
                />
              </div>
            ) : (
              <Form noValidate onSubmit={handleMessageSubmit}>
                <InputGroup className="mb-0">
                  <Form.Control
                    ref={messageField}
                    value={message}
                    placeholder="Type something"
                    onChange={(e) => setMessage(e.target.value)}
                    disabled={chatSupportMessage.buttonDisable}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit(e);
                      }
                    }}
                  />
                  <InputGroup.Text>
                    <div className="broadcast-upload-attachement-file-sec">
                      <div class="upload-btn-wrapper">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#fff"
                          viewBox="0 0 24 24"
                        >
                          <path d="M22.95 9.6a1 1 0 00-1.414 0L10.644 20.539a5 5 0 11-7.072-7.071L14.121 2.876a3 3 0 014.243 4.242L7.815 17.71a1.022 1.022 0 01-1.414 0 1 1 0 010-1.414l9.392-9.435a1 1 0 00-1.414-1.414l-9.392 9.435a3 3 0 000 4.243 3.073 3.073 0 004.243 0L19.778 8.532a5 5 0 00-7.071-7.07L2.158 12.054a7 7 0 009.9 9.9L22.95 11.018a1 1 0 000-1.418z"></path>
                        </svg>
                        <input
                          type="file"
                          accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png,video/mp4,video/x-m4v,video/*"
                          name="file"
                          id="file"
                          onChange={(event) => handleChange(event)}
                          disabled={chatSupportMessage.buttonDisable}
                        />
                      </div>
                    </div>
                  </InputGroup.Text>
                  <InputGroup.Text
                    onClick={(e) => handleSubmit(e)}
                    disabled={chatSupportMessage.buttonDisable}
                    className="pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#fff"
                      viewBox="0 0 24 24"
                    >
                      <path d="M23.119.882a2.966 2.966 0 00-2.8-.8l-16 3.37a4.995 4.995 0 00-2.853 8.481l1.718 1.717a1 1 0 01.293.708v3.168a2.965 2.965 0 00.3 1.285l-.008.007.026.026A3 3 0 005.157 20.2l.026.026.007-.008a2.965 2.965 0 001.285.3h3.168a1 1 0 01.707.292l1.717 1.717A4.963 4.963 0 0015.587 24a5.049 5.049 0 001.605-.264 4.933 4.933 0 003.344-3.986l3.375-16.035a2.975 2.975 0 00-.792-2.833zM4.6 12.238l-1.719-1.717a2.94 2.94 0 01-.722-3.074 2.978 2.978 0 012.5-2.026L20.5 2.086 5.475 17.113v-2.755a2.978 2.978 0 00-.875-2.12zm13.971 7.17a3 3 0 01-5.089 1.712l-1.72-1.72a2.978 2.978 0 00-2.119-.878H6.888L21.915 3.5z"></path>
                    </svg>
                  </InputGroup.Text>
                </InputGroup>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatWidget;
