import React, { useEffect, useState } from "react";
import { Table, Modal, Image } from "react-bootstrap";
import FsLightbox from "fslightbox-react";
import { useTranslation } from "react-multi-lang";

const ViewTransactionModal = (props) => {

  const t = useTranslation("transactions.view")
  const [toggler, setToggler] = useState(false);

  const getStatusClass = (status) => {
    switch (status) {
      case 0:
        return "payment-initiated";
      case 1:
        return "payment-onhold";
      case 2:
        return "payment-paid";
      case 3:
        return "payment-rejected";
      case 4:
        return "payment-cancelled";
      case 5: 
        return "payment-failed";
      default:
        return "payment-initiated";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 0:
        return "#5C9EEB";
      case 1:
        return "#FFCE22";
      case 2:
        return "#197E23";
      case 3:
        return "#818181";
      case 4:
        return "#FF8A00";
      case 5:
        return "#FF6A6A";
      default:
        return "#5C9EEB";
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="view-transaction-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Transaction Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="view-transaction-table">
          <Table>
            <tbody>
              <tr>
                <td>{t("order_id")}</td>
                <td>{props.show.order_id}</td>
              </tr> 
              <tr>
                <td>{t("transaction_id")}</td>
                <td>{props.show.unique_id}</td>
              </tr>
              <tr>
                <td>{t("payment_mode")}</td>
                <td>{props.show.payment_type_formatted}</td>
              </tr>
              {props.show.payment_type == "inr" && (
                <tr>
                  <td>{t("inr_type")}</td>
                  <td>{props.show.inr_type_formatted}</td>
                </tr>
              )}
              <tr>
                <td>{t("type")}</td>
                <td>{props.show.type_formatted}</td>
              </tr>
              <tr>
                <td>{t("requested_amount")}</td>
                <td>
                  <div className="transaction-amount">
                    {props.show.formatted_total}
                  </div>
                </td>
              </tr>
              <tr>
                <td>{t("settlement_amount")}</td>
                <td>
                  <div className="transaction-amount">
                    {props.show.formatted_amount}
                  </div>
                </td>
              </tr>
              <tr>
                <td>{t("merchant_commission_amount")}</td>
                <td>
                  <div className="transaction-amount">
                    {props.show.merchant_commission_amount_formatted}
                  </div>
                </td>
              </tr>
              <tr>
              <td>{t("admin_commission_amount")}</td>
              <td>
                <div className="transaction-amount">
                  {props.show.admin_commission_amount_formatted}
                </div>
              </td>
              </tr>
              {props.show.user_upi_id && (
                <tr>
                  <td>{t("user_upi_id")}</td>
                  <td>{props.show.user_upi_id}</td>
                </tr>
              )}
              {props.show.utr_number && (
                <tr>
                  <td>{t("utr_number")}</td>
                  <td>{props.show.utr_number}</td>
                </tr>
              )}
              {props.show.user_email && (
                <tr>
                  <td>{t("user_email")}</td>
                  <td>{props.show.user_email}</td>
                </tr>
              )}
              <tr>
                <td>{t("status")}</td>
                <td style={{display:"flex", justifyContent:"flex-end"}}>
                  <div className={getStatusClass(props.show.status)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      fill={getStatusColor(props.show.status)}
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0z"></path>
                    </svg>
                    {props.show.status_formatted}
                  </div>
                </td>
              </tr>
              {props.show.cancel_reason && (
                <tr>
                  <td>{t("cancel_reason")}</td>
                  <td>{props.show.cancel_reason}</td>
                </tr>
              )}
              {props.show.payment_picture && (
                <tr>
                  <td>{t("screen_shot")}</td>
                  <td>
                    {/* <button
                      className="view-screen-shots"
                      onClick={() => setToggler(!toggler)}
                    >
                      {t("view_screen_shot")}
                    </button>
                    <FsLightbox
                      toggler={toggler}
                      sources={[props.show.payment_picture, props.show.payment_picture]}
                    /> */}
                    <a style={{ textDecoration:'none' }} className="view-screen-shots" href={props.show.payment_picture} target="_blank" rel="noreferrer">
                      {t("view_screen_shot")}
                    </a>
                  </td>
                </tr>
              )}
              <tr>
                <td>{t("created_date")}</td>
                <td>{props.show.created_at}</td>
              </tr>
              <tr>
                <td>{t("updated_date")}</td>
                <td>{props.show.updated_at}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewTransactionModal;
