import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import LineGraph from "./LineGraph";
import BarGraph from "./BarGraph";
import RecentTransaction from "./RecentTransaction";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardChartStart,
  dashboardStart,
  profileStart,
} from "../../store/slices/AdminSlice";
import { transactionListStart } from "../../store/slices/PaymentSlice";
import Skeleton from "react-loading-skeleton";
import DashboardLoader from "../Helper/DashboardLoader";
import VerifyKYCModal from "../Account/VerifyKYCModal";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import PieGraph from "./PieGraph";
import NoDataFound from "../Helper/NoDataFound";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { useTranslation, withTranslation } from "react-multi-lang";

const DashBoardIndex = (props) => {
  const t = useTranslation("dashboard");
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.admin.dashboard);
  const dashboardChart = useSelector((state) => state.admin.dashboardChart);
  const profile = useSelector((state) => state.admin.profile);

  const [verifyKYC, setVerifyKYC] = useState(false);

  const closeVerifyKYCModal = () => {
    setVerifyKYC(false);
  };

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(dashboardStart());
      dispatch(dashboardChartStart());
      dispatch(transactionListStart());
    }
  }, []);

  return profile.loading ? (
    <DashboardLoader />
  ) : Object.keys(profile.data).length > 0 ? (
    <>
      {profile.data.kyc_status == 3 ? (
        profile.data.payin_access == 1 || profile.data.payout_access == 1 ? (
          <React.Fragment>
            <div className="dashboard-sec">
              <div className="dashboard-header-sec">
                <div className="dashboard-box">
                  {dashboard.loading ? (
                    [...Array(profile.data.payin_access == 1 && profile.data.payout_access == 1 ? 8 : 4)].map((i, key) => (
                      <Skeleton key={key} height={100} />
                    ))
                  ) : Object.keys(dashboard.data).length > 0 ? (
                    <React.Fragment>
                      {profile.data.payin_access == 1 ? (
                        <>
                          <div className="dashboard-card">
                            <p>
                              {t(
                                "dashboard_card.payin_total_transactions_count"
                              )}
                            </p>
                            <h4>
                              {dashboard.data.payin_total_transactions_count}
                            </h4>
                          </div>
                          <div className="dashboard-card">
                            <p>
                              {t("dashboard_card.payin_total_success_amount")}
                            </p>
                            <h4>{dashboard.data.payin_total_success_amount}</h4>
                          </div>
                          <div className="dashboard-card">
                            <p>
                              {t(
                                "dashboard_card.payin_today_transactions_count"
                              )}
                            </p>
                            <h4>
                              {dashboard.data.payin_today_transactions_count}
                            </h4>
                          </div>
                          <div className="dashboard-card">
                            <p>
                              {t("dashboard_card.payin_today_success_amount")}
                            </p>
                            <h4>{dashboard.data.payin_today_success_amount}</h4>
                          </div>
                        </>
                      ) : null}
                      {profile.data.payout_access == 1 ? (
                        <>
                          <div className="dashboard-card">
                            <p>
                              {t(
                                "dashboard_card.payout_total_transactions_count"
                              )}
                            </p>
                            <h4>
                              {dashboard.data.payout_total_transactions_count}
                            </h4>
                          </div>
                          <div className="dashboard-card">
                            <p>
                              {t("dashboard_card.payout_total_success_amount")}
                            </p>
                            <h4>
                              {dashboard.data.payout_total_success_amount}
                            </h4>
                          </div>
                          <div className="dashboard-card">
                            <p>
                              {t(
                                "dashboard_card.payout_today_transactions_count"
                              )}
                            </p>
                            <h4>
                              {dashboard.data.payout_today_transactions_count}
                            </h4>
                          </div>
                          <div className="dashboard-card">
                            <p>
                              {t("dashboard_card.payout_today_success_amount")}
                            </p>
                            <h4>
                              {dashboard.data.payout_today_success_amount}
                            </h4>
                          </div>
                        </>
                      ) : null}
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
              {profile.data.payin_access == 1 ? (
                <>
                  <div className="dashboard-body-sec">
                    {dashboardChart.loading ? (
                      <React.Fragment>
                        <div className="dashboard-transaction-box">
                          <div className="dashboard-transaction-card">
                            <Skeleton height={300} />
                          </div>
                        </div>
                        <div className="dashboard-transaction-multiple-graph-sec">
                          <div className="dashboard-transaction-multiple-card">
                            <Skeleton height={300} />
                          </div>
                          <div className="dashboard-transaction-multiple-card">
                            <Skeleton height={300} />
                          </div>
                        </div>
                      </React.Fragment>
                    ) : Object.keys(dashboardChart.data).length > 0 ? (
                      <React.Fragment>
                        <div className="dashboard-transaction-box">
                          <div className="dashboard-transaction-card">
                            <div className="dashboard-transaction-top-sec">
                              <div className="dashboard-transaction-left-sec">
                                <h5>
                                  {t(
                                    "dashboard_card.transaction_overview_label"
                                  )}
                                </h5>
                              </div>
                            </div>
                            <div className="dasboard-transaction-graph-sec">
                              <LineGraph
                                payinData={
                                  dashboardChart.data.last_x_months_pay_in
                                }
                                payoutData={
                                  dashboardChart.data.last_x_months_pay_out
                                }
                                count={
                                  dashboardChart.data.total_transactions_count
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="dashboard-transaction-multiple-graph-sec">
                          <div className="dashboard-transaction-multiple-card">
                            <div className="dashboard-transaction-top-multiple-sec">
                              <div className="dashboard-transaction-multiple-left-sec">
                                <h5>
                                  {t(
                                    "dashboard_card.last_10_days_transactions_label"
                                  )}
                                </h5>
                              </div>
                            </div>
                            <div className="dashboard-transaction-analytics-graph-sec">
                              <BarGraph
                                data={
                                  dashboardChart.data.last_x_days_transactions
                                }
                                count={
                                  dashboardChart.data.total_transactions_count
                                }
                              />
                            </div>
                          </div>
                          <div className="dashboard-transaction-multiple-card">
                            <div className="dashboard-transaction-top-multiple-sec">
                              <div className="dashboard-transaction-multiple-left-sec">
                                <h5>
                                  {t(
                                    "dashboard_card.transaction_traffic_label"
                                  )}
                                </h5>
                              </div>
                            </div>
                            <div className="dashboard-transaction-analytics-graph-sec">
                              <PieGraph
                                data={dashboardChart.data.total_transactions}
                                count={
                                  dashboardChart.data.total_transactions_count
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : null}
                    <RecentTransaction />
                  </div>
                </>
              ) : null}
            </div>
          </React.Fragment>
        ) : (
          <div className="profile-account-sec">
            <div className="profile-account-box">
              <div className="profile-account-header-sec">
                <div className="profile-account-header-left-sec">
                  <div className="profile-account-user-details-sec">
                    <div className="profile-account-user-img-sec">
                      <CustomLazyLoad
                        className="profile-account-user-img"
                        src={
                          profile.data.picture ||
                          window.location.origin + "/img/user-img.svg"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="profile-account-user-info">
                      <h4>{profile.data.name}</h4>
                      <ul className="list-unstyled profile-account-user-info-list">
                        <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 16 14"
                          >
                            <path
                              fill="#020E30"
                              d="M12.667.5H3.333c-.883 0-1.73.313-2.356.866C.353 1.92.001 2.671 0 3.455v7.09c.001.784.353 1.535.977 2.089.625.553 1.473.865 2.356.866h9.334c.883 0 1.73-.313 2.356-.866.624-.554.976-1.305.977-2.088V3.455c-.001-.784-.353-1.535-.977-2.089C14.398.813 13.55.501 12.667.5zM3.333 1.682h9.334c.399 0 .789.107 1.119.306.33.199.586.48.734.809L9.415 7.323A2.143 2.143 0 018 7.84c-.53 0-1.039-.187-1.415-.518L1.48 2.797c.148-.329.404-.61.734-.81.33-.198.72-.304 1.12-.305zm9.334 10.636H3.333c-.53 0-1.039-.187-1.414-.519a1.677 1.677 0 01-.586-1.253V4.34l4.31 3.817c.625.553 1.473.864 2.357.864.884 0 1.732-.31 2.357-.864l4.31-3.817v6.205c0 .47-.211.92-.586 1.253-.375.332-.884.52-1.414.52z"
                            ></path>
                          </svg>
                          <span>{profile.data.email}</span>
                        </li>
                        <li>
                          <svg
                            xmlns="http://w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill="#0C0E10"
                              fillRule="evenodd"
                              d="M1.607 1.056C3.143-.48 5.736-.363 6.806 1.554l.593 1.063c.698 1.251.4 2.83-.622 3.866a.576.576 0 00-.095.308c-.012.234.072.776.912 1.615.84.84 1.38.924 1.616.912a.576.576.0 00.308-.095c1.034-1.022 2.614-1.32 3.865-.622l1.063.595c1.917 1.07 2.034 3.66.498 5.197-.822.823-1.914 1.553-3.199 1.6-1.902.074-5.062-.418-8.19-3.548C.425 9.317-.066 6.158.005 4.255c.049-1.285.78-2.377 1.6-3.199zm4 1.166c-.548-.982-2.02-1.207-3.03-.195-.709.708-1.17 1.49-1.2 2.28-.06 1.587.332 4.354 3.147 7.168 2.817 2.817 5.583 3.209 7.17 3.149.788-.03 1.572-.492 2.28-1.2 1.01-1.011.786-2.484-.196-3.031l-1.064-.594c-.66-.368-1.59-.243-2.242.41-.064.064-.472.444-1.196.479-.74.037-1.637-.296-2.651-1.311-1.016-1.015-1.349-1.912-1.312-2.654.034-.723.416-1.131.479-1.195.653-.652.778-1.581.41-2.242l-.595-1.064z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span>
                            +{profile.data.country_code} {profile.data.mobile}
                          </span>
                        </li>
                      </ul>
                      {/* <p>{t("no_access_for_payin_payout")}</p> */}
                    </div>
                  </div>
                </div>
                <div className="profile-account-header-right-sec">
                  <div className="profile-account-header-kyc-verified-btn">
                    {/* <Image
                      className="kyc-verified-icon"
                      src={window.location.origin + "/img/kyc-verified.svg"}
                      type="image/png"
                    /> */}
                  </div>
                  <h5>{t("no_access_for_payin_payout")}</h5>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="profile-account-sec">
          <div className="profile-account-box">
            <div className="profile-account-header-sec">
              <div className="profile-account-header-left-sec">
                <div className="profile-account-user-details-sec">
                  <div className="profile-account-user-img-sec">
                    <CustomLazyLoad
                      className="profile-account-user-img"
                      src={
                        profile.data.picture ||
                        window.location.origin + "/img/user-img.svg"
                      }
                      type="image/png"
                    />
                  </div>
                  <div className="profile-account-user-info">
                    <h4>{profile.data.name}</h4>
                    <ul className="list-unstyled profile-account-user-info-list">
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 16 14"
                        >
                          <path
                            fill="#020E30"
                            d="M12.667.5H3.333c-.883 0-1.73.313-2.356.866C.353 1.92.001 2.671 0 3.455v7.09c.001.784.353 1.535.977 2.089.625.553 1.473.865 2.356.866h9.334c.883 0 1.73-.313 2.356-.866.624-.554.976-1.305.977-2.088V3.455c-.001-.784-.353-1.535-.977-2.089C14.398.813 13.55.501 12.667.5zM3.333 1.682h9.334c.399 0 .789.107 1.119.306.33.199.586.48.734.809L9.415 7.323A2.143 2.143 0 018 7.84c-.53 0-1.039-.187-1.415-.518L1.48 2.797c.148-.329.404-.61.734-.81.33-.198.72-.304 1.12-.305zm9.334 10.636H3.333c-.53 0-1.039-.187-1.414-.519a1.677 1.677 0 01-.586-1.253V4.34l4.31 3.817c.625.553 1.473.864 2.357.864.884 0 1.732-.31 2.357-.864l4.31-3.817v6.205c0 .47-.211.92-.586 1.253-.375.332-.884.52-1.414.52z"
                          ></path>
                        </svg>
                        <span>{profile.data.email}</span>
                      </li>
                      <li>
                        <svg
                          xmlns="http://w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="#0C0E10"
                            fillRule="evenodd"
                            d="M1.607 1.056C3.143-.48 5.736-.363 6.806 1.554l.593 1.063c.698 1.251.4 2.83-.622 3.866a.576.576 0 00-.095.308c-.012.234.072.776.912 1.615.84.84 1.38.924 1.616.912a.576.576.0 00.308-.095c1.034-1.022 2.614-1.32 3.865-.622l1.063.595c1.917 1.07 2.034 3.66.498 5.197-.822.823-1.914 1.553-3.199 1.6-1.902.074-5.062-.418-8.19-3.548C.425 9.317-.066 6.158.005 4.255c.049-1.285.78-2.377 1.6-3.199zm4 1.166c-.548-.982-2.02-1.207-3.03-.195-.709.708-1.17 1.49-1.2 2.28-.06 1.587.332 4.354 3.147 7.168 2.817 2.817 5.583 3.209 7.17 3.149.788-.03 1.572-.492 2.28-1.2 1.01-1.011.786-2.484-.196-3.031l-1.064-.594c-.66-.368-1.59-.243-2.242.41-.064.064-.472.444-1.196.479-.74.037-1.637-.296-2.651-1.311-1.016-1.015-1.349-1.912-1.312-2.654.034-.723.416-1.131.479-1.195.653-.652.778-1.581.41-2.242l-.595-1.064z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>
                          +{profile.data.country_code} {profile.data.mobile}
                        </span>
                      </li>
                    </ul>
                    <p>{t("kyc_not_verified_label")}</p>
                  </div>
                </div>
              </div>
              {profile.data.kyc_status === 3 ? (
                <div className="profile-account-header-right-sec">
                  <div className="profile-account-header-kyc-verified-btn">
                    <Image
                      className="kyc-verified-icon"
                      src={window.location.origin + "/img/kyc-verified.svg"}
                      type="image/png"
                    />
                  </div>
                  <h5>{t("kyc_verified_label")}</h5>
                </div>
              ) : (
                <div className="profile-account-header-kyc-verified-btn">
                  <Button
                    className="efi-auth-btn"
                    onClick={() => setVerifyKYC(true)}
                  >
                    {t("verify_kyc_btn")}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {verifyKYC && (
        <VerifyKYCModal
          verifyKYC={verifyKYC}
          closeVerifyKYCModal={closeVerifyKYCModal}
          setVerifyKYC={setVerifyKYC}
        />
      )}
    </>
  ) : (
    <SomethingWentWrong
      buttonText={t("retry_btn")}
      handleClick={() => dispatch(profileStart())}
    />
  );
};

export default withTranslation(DashBoardIndex);
