import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  requestZyroPayOutStart,
} from "../../store/slices/PayoutSlice";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";

const RequestZyroPayoutModal = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("payout_transactions");
  const profile = useSelector((state) => state.admin.profile);
  const requestPayOut = useSelector((state) => state.payout.requestZyroPayOut);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [skipRender, setSkipRender] = useState(true);

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      cursor: "pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#50506B80",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const validationSchema = Yup.object().shape({
    beneficiaryIFSC: Yup.string()
        .required(t("beneficiaryIFSC.required")),
    beneficiaryAccount: Yup.number().required(t("beneficiaryAccount.required")),
    beneficiaryName: Yup.string().required(t("beneficiaryName.required")),
    beneficiaryAddress: Yup.string().required(t("beneficiaryAddress.required")),
    paymentMode: Yup.string().required(t("paymentMode.required"))
      .oneOf(['NEFT', 'IMPS', 'RTGS'], t("paymentMode.invalid")),
    amount: Yup.number()
      .test(t("requested_amount.invalid"), (value) => {
        if (!value) return true;
        else {
          return !isNaN(value);
        }
      })
      .required(t("requested_amount.required"))
      .min(1, t("requested_amount.amount_must_be_at_least"))
      .test('max', t('requested_amount.amount_must_be_at_most'), (value) => {
        return value <= 10000000;
      }),
  });

  const tokenOptions = [
    { label: "NEFT", value: "NEFT" },
    { label: "IMPS", value: "IMPS" },
    { label: "RTGS", value: "RTGS" },
  ];

  const handleSubmit = (values) => {
    dispatch(
      requestZyroPayOutStart({
        ...values,
        walletId: "A88C982B-49F6-42C6-ADBB-C8BD8A3E8181"
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !requestPayOut.loading &&
      Object.keys(requestPayOut.data).length > 0
    ) {
      props.closeRequestPayoutModal();
    }
    setSkipRender(false);
  }, [requestPayOut]);

  return (
      <>
        <Modal
          className="modal-dialog-center payment-link-created-modal"
          size="md"
          centered
          show={props.requestPayout}
          onHide={props.closeRequestPayoutModal}
          backdrop="static"
        >
          <Modal.Body>
            <Button
              onClick={() => props.closeRequestPayoutModal()}
              className="modal-close"
            >
              <Image
                className="close-icon"
                src={window.location.origin + "/img/close-modal-icon.svg"}
              />
            </Button>
            <div className="request-payout-sec">
              <h3>{t("heading")}</h3>
              <div className="request-payout-amount-box">
                <div className="request-payout-amount-card">
                  <h4>{props.zyroPayoutBalance.balanceAmount}</h4>
                  <p>{t("your_current_balance")}</p>
                </div>
              </div>
              <Formik
                initialValues={{
                  requestId: (Math.random() + 1).toString(36).substring(7),
                  amount: "",
                  beneficiaryIFSC: "",
                  beneficiaryAccount: "",
                  beneficiaryName: "",
                  beneficiaryAddress: "",
                  paymentMode: "",
                  remarks: "",
                  debitAccount: true,
                  paymentType: "live",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue, setFieldTouched, errors }) => (
                  <FORM className="bank-account-form">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>{t("requested_amount.label")}</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={t("requested_amount.placeholder")}
                        name="amount"
                        type="text" 
                        pattern="\d*" 
                        onKeyDown={(e) => {
                          if (!/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        />
                      <ErrorMessage
                        component={"div"}
                        name="amount"
                        className="errorMsg w-100"
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>{t("beneficiaryAccount.label")}</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={t("beneficiaryAccount.placeholder")}
                        name="beneficiaryAccount"
                        type="number"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="beneficiaryAccount"
                        className="errorMsg w-100"
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>{t("beneficiaryIFSC.label")}</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={t("beneficiaryIFSC.placeholder")}
                        name="beneficiaryIFSC"
                        type="text"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="beneficiaryIFSC"
                        className="errorMsg w-100"
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>{t("beneficiaryName.label")}</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={t("beneficiaryName.placeholder")}
                        name="beneficiaryName"
                        type="text"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="beneficiaryName"
                        className="errorMsg w-100"
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>{t("beneficiaryAddress.label")}</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={t("beneficiaryAddress.placeholder")}
                        name="beneficiaryAddress"
                        type="text"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="beneficiaryAddress"
                        className="errorMsg w-100"
                      />
                    </Form.Group>
                  
                    <React.Fragment>
                      <Form.Group className="mb-4">
                        <Form.Label>{t("paymentMode.label")}</Form.Label>
                        <Select
                          options={tokenOptions}
                          styles={customStyles}
                          isSearchable={false}
                          onChange={(selectedOption) => {
                            setFieldValue("paymentMode", selectedOption.value);
                          }}
                          placeholder={<div>{t("paymentMode.placeholder")}</div>}
                          name="paymentMode"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="paymentMode"
                          className="errorMsg w-100"
                        />
                      </Form.Group>
                    </React.Fragment>
                    <Form.Group
                      className="mb-0"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Remarks</Form.Label>
                      <Field
                        as="textarea"
                        rows="4"
                        className="form-control"
                        placeholder="Enter remarks"
                        name="remarks"
                        onChange={(e)=> setFieldValue('remarks', e.target.value)}
                      />
                    </Form.Group>

                    <div className="request-payout-payment-method-btn-sec">
                      <Button
                        className="efi-cancel-btn"
                        onClick={() => props.closeRequestPayoutModal()}
                      >
                       {t("cancel")}
                      </Button>
                      <Button
                        type="submit"
                        className="efi-auth-btn"
                        disabled={requestPayOut.buttonDisable}
                      >
                        {requestPayOut.buttonDisable ? (
                          <ButtonLoader />
                        ) : (
                          t("payment_btn.text")
                        )}
                      </Button>
                    </div>
                  </FORM>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
      </>
  );
};

export default RequestZyroPayoutModal;
