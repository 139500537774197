import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  changePasswordFailure,
  changePasswordSuccess,
  dashboardFailure,
  dashboardSuccess,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  generateAPIKeyFailure,
  generateAPIKeySuccess,
  loginFailure,
  loginSuccess,
  profileFailure,
  profileSuccess,
  registerFailure,
  registerSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  updateProfileFailure,
  updateProfileSuccess,
  viewAPIKeyFailure,
  viewAPIKeySuccess,
  kycApplicantFailure,
  kycApplicantSuccess,
  kycStatusUpdateFailure,
  kycStatusUpdateSuccess,
  verifyForgotPasswordSuccess,
  verifyForgotPasswordFailure,
  deleteProfileSuccess,
  deleteProfileFailure,
  emailVerifySuccess,
  emailVerifyFailure,
  twoStepAuthSuccess,
  twoStepAuthFailure,
  updateTwoStepSuccess,
  updateTwoStepFailure,
  resendOtpSuccess,
  resendOtpFailure,
  dashboardChartSuccess,
  dashboardChartFailure,
  updateEmailCodeSuccess,
  updateEmailCodeFailure,
  updateEmailSuccess,
  updateEmailFailure,
  apiKeyViewSuccess,
  apiKeyViewFailure,
  apiKeyRegenerateSuccess,
  apiKeyRegenerateFailure
} from "../slices/AdminSlice";
import { apiUrl } from "../../Environment";


function* loginAPI(action) {
  yield getCommonSaga({
    apiUrl: "login",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(loginSuccess({ ...data.user, remember: action.payload.remember }));
      if (data.user.email_status === 1) {
        if (action.payload.remember) {
          localStorage.setItem("token", data.access_token)
          // localStorage.setItem("central_token", data.user.central_api_key)
          localStorage.setItem("api_key", data.api_key)
          // localStorage.setItem("payout_api_key", data.payout_api_key)
          localStorage.setItem("salt_key", data.salt_key)
        }
        else {
          sessionStorage.setItem("token", data.access_token)
          // sessionStorage.setItem("central_token", data.user.central_api_key)
          sessionStorage.setItem("api_key", data.api_key)
          // sessionStorage.setItem("payout_api_key", data.payout_api_key)
          sessionStorage.setItem("salt_key", data.salt_key)
        }
        store.dispatch(profileSuccess(data.user));
      }
    },
    failure: loginFailure,
  });
}

function* registerAPI(action) {
  yield getCommonSaga({
    apiUrl: "register",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(registerSuccess({ ...data.user, remember: action.payload.remember }));
      if (data.user.email_status === 1) {
        if (action.payload.remember) {
          localStorage.setItem("token", data.access_token)
          // localStorage.setItem("central_token", data.user.central_api_key)
          localStorage.setItem("api_key", data.api_key)
          // localStorage.setItem("payout_api_key", data.payout_api_key)
          localStorage.setItem("salt_key", data.salt_key)
        }
        else {
          sessionStorage.setItem("token", data.access_token)
          // sessionStorage.setItem("central_token", data.user.central_api_key)
          sessionStorage.setItem("api_key", data.api_key)
          // sessionStorage.setItem("payout_api_key", data.payout_api_key)
          sessionStorage.setItem("salt_key", data.salt_key)
        }
        store.dispatch(profileSuccess(data.user));
      }
    },
    failure: registerFailure,
  });
}

function* forgotPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "forgot_password",
    payload: action.payload,
    success: forgotPasswordSuccess,
    failure: forgotPasswordFailure,
  });
}

function* resetPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "reset_password",
    payload: action.payload,
    success: resetPasswordSuccess,
    failure: resetPasswordFailure,
  });
}

function* profileAPI(action) {
  yield getCommonSaga({
    apiUrl: "profile",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => store.dispatch(profileSuccess(data.user)),
    failure: profileFailure,
  });
}

function* updateProfileAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_profile",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(profileSuccess(data.user));
      store.dispatch(updateProfileSuccess(data));
    },
    failure: updateProfileFailure,
  });
}

function* dashboardAPI(action) {
  yield getCommonSaga({
    apiUrl: "dashboard",
    payload: action.payload,
    successNotify: false,
    success: dashboardSuccess,
    failure: dashboardFailure,
  });
}

function* dashboardChartAPI(action) {
  yield getCommonSaga({
    apiUrl: "dashboard_chart",
    payload: action.payload,
    successNotify: false,
    success: dashboardChartSuccess,
    failure: dashboardChartFailure,
  });
}

function* generateAPIKeyAPI(action) {
  yield getCommonSaga({
    apiUrl: "generate_api_key",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(generateAPIKeySuccess(data));
      store.dispatch(viewAPIKeySuccess(data));
    },
    failure: generateAPIKeyFailure,
  });
}

function* viewAPIKeyAPI(action) {
  yield getCommonSaga({
    apiUrl: "view_central_api_key",
    payload: action.payload,
    successNotify: false,
    success: viewAPIKeySuccess,
    failure: viewAPIKeyFailure,
  });
}

function* kycApplicantAPI(action) {
  yield getCommonSaga({
    apiUrl: "kyc_create_applicant",
    payload: action.payload,
    successNotify: false,
    success: kycApplicantSuccess,
    failure: kycApplicantFailure,
  });
}

function* kycStatusUpdateAPI(action) {
  yield getCommonSaga({
    apiUrl: "kyc_status_update",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(profileSuccess(data.user));
      store.dispatch(kycStatusUpdateSuccess(data));
    },
    failure: kycStatusUpdateFailure,
  });
}

function* changePasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "change_password",
    payload: action.payload,
    successCallback: true,
    success: data => store.dispatch(changePasswordSuccess({ data: data })),
    failure: changePasswordFailure,
  });
}

function* verifyForgotPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "verify_forgot_password_code",
    payload: action.payload,
    success: verifyForgotPasswordSuccess,
    failure: verifyForgotPasswordFailure,
  });
}

function* deleteProfileAPI(action) {
  yield getCommonSaga({
    apiUrl: "delete_account",
    payload: action.payload,
    success: deleteProfileSuccess,
    failure: deleteProfileFailure,
  });
}

function* emailVerifyAPI(action) {
  yield getCommonSaga({
    apiUrl: "verify_email",
    payload: action.payload,
    successCallback: true,
    success: data => {
      // if (action.payload.remember) localStorage.setItem("token", data.access_token);
      // else sessionStorage.setItem("token", data.access_token);
      if (action.payload.remember) {
        localStorage.setItem("token", data.access_token)
        // localStorage.setItem("central_token", data.user.central_api_key)
        localStorage.setItem("api_key", data.api_key)
        // localStorage.setItem("payout_api_key", data.payout_api_key)
        localStorage.setItem("salt_key", data.salt_key)
      }
      else {
        sessionStorage.setItem("token", data.access_token)
        // sessionStorage.setItem("central_token", data.user.central_api_key)
        sessionStorage.setItem("api_key", data.api_key)
        // sessionStorage.setItem("payout_api_key", data.payout_api_key)
        sessionStorage.setItem("salt_key", data.salt_key)
      }
      store.dispatch(emailVerifySuccess(data.user));
      store.dispatch(profileSuccess(data.user));
    },
    failure: emailVerifyFailure,
  });
}

function* twoStepAuthAPI(action) {
  yield getCommonSaga({
    apiUrl: "tfa_login",
    payload: action.payload,
    successCallback: true,
    success: data => {
      // if (action.payload.remember) localStorage.setItem("token", data.access_token);
      // else sessionStorage.setItem("token", data.access_token);
      if (action.payload.remember) {
        localStorage.setItem("token", data.access_token)
        // localStorage.setItem("central_token", data.user.central_api_key)
        localStorage.setItem("api_key", data.api_key)
        // localStorage.setItem("payout_api_key", data.payout_api_key)
        localStorage.setItem("salt_key", data.salt_key)
      }
      else {
        sessionStorage.setItem("token", data.access_token)
        // sessionStorage.setItem("central_token", data.user.central_api_key)
        sessionStorage.setItem("api_key", data.api_key)
        // sessionStorage.setItem("payout_api_key", data.payout_api_key)
        sessionStorage.setItem("salt_key", data.salt_key)
      }
      store.dispatch(twoStepAuthSuccess(data.user));
      store.dispatch(profileSuccess(data.user));
    },
    failure: twoStepAuthFailure,
  });
}

function* updateTwoStepAPI(action) {
  yield getCommonSaga({
    apiUrl: "tfa_status",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(updateTwoStepSuccess(data));
      store.dispatch(profileSuccess(data.user));
    },
    failure: updateTwoStepFailure,
  });
}

function* resendOtpAPI(action) {
  yield getCommonSaga({
    apiUrl: "send_verification_code",
    payload: action.payload,
    success: resendOtpSuccess,
    failure: resendOtpFailure,
  });
}

function* updateEmailCodeAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_email_verification_code",
    payload: action.payload,
    success: updateEmailCodeSuccess,
    failure: updateEmailCodeFailure,
  });
}

function* updateEmailAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_email",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(profileSuccess(data.user));
      store.dispatch(updateEmailSuccess(data));
    },
    failure: updateEmailFailure,
  });
}

function* apikeyViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "view_api_key",
    payload: action.payload,
    successNotify: false,
    success: apiKeyViewSuccess,
    failure: apiKeyViewFailure,
  });
}

function* apiKeyGenerateAPI(action) {
  const profileData = yield select(state => state.admin.profile.data);
  yield getCommonSaga({
    apiUrl: "generate_api_key",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(apiKeyRegenerateSuccess(data));
      if (Object.keys(profileData).length > 0) {
        store.dispatch(profileSuccess({ ...profileData, api_key_generated: 1 }));
      }
    },
    failure: apiKeyRegenerateFailure,
  });
}

export default function* AdminSaga() {
  yield all([
    yield takeLatest('admin/loginStart', loginAPI),
    yield takeLatest('admin/registerStart', registerAPI),
    yield takeLatest('admin/forgotPasswordStart', forgotPasswordAPI),
    yield takeLatest('admin/resetPasswordStart', resetPasswordAPI),
    yield takeLatest('admin/profileStart', profileAPI),
    yield takeLatest('admin/updateProfileStart', updateProfileAPI),
    yield takeLatest('admin/dashboardStart', dashboardAPI),
    yield takeLatest('admin/dashboardChartStart', dashboardChartAPI),
    yield takeLatest('admin/generateAPIKeyStart', generateAPIKeyAPI),
    yield takeLatest('admin/viewAPIKeyStart', viewAPIKeyAPI),
    yield takeLatest('admin/kycApplicantStart', kycApplicantAPI),
    yield takeLatest('admin/kycStatusUpdateStart', kycStatusUpdateAPI),
    yield takeLatest('admin/changePasswordStart', changePasswordAPI),
    yield takeLatest('admin/verifyForgotPasswordStart', verifyForgotPasswordAPI),
    yield takeLatest('admin/deleteProfileStart', deleteProfileAPI),
    yield takeLatest('admin/emailVerifyStart', emailVerifyAPI),
    yield takeLatest('admin/twoStepAuthStart', twoStepAuthAPI),
    yield takeLatest('admin/updateTwoStepStart', updateTwoStepAPI),
    yield takeLatest('admin/resendOtpStart', resendOtpAPI),
    yield takeLatest('admin/updateEmailCodeStart', updateEmailCodeAPI),
    yield takeLatest('admin/updateEmailStart', updateEmailAPI),
    yield takeLatest('admin/apiKeyViewStart', apikeyViewAPI),
    yield takeLatest('admin/apiKeyRegenerateStart', apiKeyGenerateAPI),
  ]);
}

