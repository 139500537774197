import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Dropdown,
  Image,
  Row,
  Tab,
  Nav,
  Col,
  Form,
  InputGroup,
} from "react-bootstrap";
import ChangeMobileNumberModal from "./ChangeMobileNumberModal";
import ChangeUserNameModal from "./ChangeUserNameModal";
import ChangePasswordModal from "./ChangePasswordModal";
import ChangeEmailAddressModal from "./ChangeEmailAddressModal";
import DeleteAccountModal from "./DeleteAccountModal";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import {
  profileStart,
  updateProfileStart,
} from "../../store/slices/AdminSlice";
import useLogout from "../../hooks/useLogout";
import VerifyKYCModal from "./VerifyKYCModal";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import OTPVerificationModal from "../Auth/OTPVerificationModal";
import ApiKeyModal from "./ApiKeyModal";
import { useTranslation } from "react-multi-lang";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";

const AccountIndex = (props) => {
  const dispatch = useDispatch();
  const { logout } = useLogout();
  const t = useTranslation("account_settings");
  const profile = useSelector((state) => state.admin.profile);
  const updateProfile = useSelector((state) => state.admin.updateProfile);
  const kycStatusUpdate = useSelector((state) => state.admin.kycStatusUpdate);
  const updateEmailCode = useSelector((state) => state.admin.updateEmailCode);
  const [changeUserName, setChangeUserName] = useState(false);
  const [changeMobileNumber, setchangeMobileNumber] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [showVerify, setShowVerify] = useState(false);
  const [newEmail, setNewEmail] = useState();

  const closeChangeUserNameModal = () => {
    setChangeUserName(false);
  };
  const closeChangeMobileNumberModal = () => {
    setchangeMobileNumber(false);
  };

  const [changePassword, setChangePassword] = useState(false);

  const closeChangePasswordModal = () => {
    setChangePassword(false);
  };

  const [changeEmailAddress, setChangeEmailAddress] = useState(false);

  const closeChangeEmailAddressModal = () => {
    setChangeEmailAddress(false);
  };

  const [deleteAccount, setDeleteAccount] = useState(false);

  const closeDeleteAccountModal = () => {
    setDeleteAccount(false);
  };

  const [verifyKYC, setVerifyKYC] = useState(false);

  const closeVerifyKYCModal = () => {
    setVerifyKYC(false);
  };

  const [apiKeyModal, setApiKeyModal] = useState(false);

  const closeApiKeyModal = () => {
    setApiKeyModal(false);
  };

  const handleUpdateProfilePicture = (image) => {
    image &&
      dispatch(
        updateProfileStart({
          picture: image,
          name: profile.data.name,
          email: profile.data.email,
          mobile: profile.data.mobile,
          country_code: profile.data.country_code,
          citizenship: profile.data.citizenship,
          residence: profile.data.residence,
        })
      );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !kycStatusUpdate.loading &&
      Object.keys(kycStatusUpdate.data).length > 0
    ) {
      closeVerifyKYCModal();
    }
    setSkipRender(false);
  }, [kycStatusUpdate]);

  useEffect(() => {
    if (
      !skipRender &&
      !updateEmailCode.loading &&
      Object.keys(updateEmailCode.data).length > 0
    ) {
      setShowVerify(newEmail);
    }
    setSkipRender(false);
  }, [updateEmailCode]);

  const zeroPad = (num, places) => String(num).padStart(places, '0')

  return profile.loading ? (
    <PageLoader />
  ) : Object.keys(profile.data).length > 0 ? (
    <>
      <div className="profile-account-sec">
        <div className="settlement-top-sec">
          <h3>{t("heading")}</h3>
        </div>
        <div className="profile-account-box">
          <div className="profile-account-header-sec">
            <div className="profile-account-header-left-sec">
              <div className="profile-account-user-details-sec">
                <div className="profile-account-user-img-sec">
                  {updateProfile.buttonDisable ? <CustomLazyLoad
                    className="profile-account-user-img"
                    src={window.location.origin + "/img/image-pulse.svg"}
                    type="image/png"
                  /> : <CustomLazyLoad
                    className="profile-account-user-img"
                    src={
                      profile.data.picture ||
                      window.location.origin + "/img/user-img.svg"
                    }
                    type="image/png"
                  />}
                  <label className="profile-account-user-img-edit-icon mb-3">
                    <input
                      type="file"
                      accept="image/png, image/jpeg,, image/jpg"
                      size="60"
                      disabled={updateProfile.buttonDisable}
                      onChange={(e) =>
                        handleUpdateProfilePicture(e.target.files[0])
                      }
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                      width="30"
                      height="30"
                      fill="#0c768a"
                    >
                      <path d="M13.75 11.664l-3.457 3.457A3.02 3.02 0 018.172 16H8v-.172c0-.789.32-1.562.879-2.121l3.457-3.457 1.414 1.414zm1.043-3.871L13.75 8.836l1.414 1.414 1.043-1.043a1 1 0 00-1.414-1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-6.379-5.621a3.002 3.002 0 00-4.242 0l-5.914 5.914A4.968 4.968 0 006 15.828V17a1 1 0 001 1h1.172a4.968 4.968 0 003.535-1.465l5.914-5.914a3.002 3.002 0 000-4.242z"></path>
                    </svg>
                  </label>
                </div>
                <div className="profile-account-user-info">
                  <h4>{profile.data.name}</h4>
                  <p>
                    {t("manage_your_personal_information_email_password_more")}
                  </p>
                  <ul className="list-unstyled profile-account-user-info-list">
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 16 14"
                      >
                        <path
                          fill="#020E30"
                          d="M12.667.5H3.333c-.883 0-1.73.313-2.356.866C.353 1.92.001 2.671 0 3.455v7.09c.001.784.353 1.535.977 2.089.625.553 1.473.865 2.356.866h9.334c.883 0 1.73-.313 2.356-.866.624-.554.976-1.305.977-2.088V3.455c-.001-.784-.353-1.535-.977-2.089C14.398.813 13.55.501 12.667.5zM3.333 1.682h9.334c.399 0 .789.107 1.119.306.33.199.586.48.734.809L9.415 7.323A2.143 2.143 0 018 7.84c-.53 0-1.039-.187-1.415-.518L1.48 2.797c.148-.329.404-.61.734-.81.33-.198.72-.304 1.12-.305zm9.334 10.636H3.333c-.53 0-1.039-.187-1.414-.519a1.677 1.677 0 01-.586-1.253V4.34l4.31 3.817c.625.553 1.473.864 2.357.864.884 0 1.732-.31 2.357-.864l4.31-3.817v6.205c0 .47-.211.92-.586 1.253-.375.332-.884.52-1.414.52z"
                        ></path>
                      </svg>
                      <span>{profile.data.email}</span>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="#0C0E10"
                          fillRule="evenodd"
                          d="M1.607 1.056C3.143-.48 5.736-.363 6.806 1.554l.593 1.063c.698 1.251.4 2.83-.622 3.866a.576.576 0 00-.095.308c-.012.234.072.776.912 1.615.84.84 1.38.924 1.616.912a.576.576 0 00.308-.095c1.034-1.022 2.614-1.32 3.865-.622l1.063.595c1.917 1.07 2.034 3.66.498 5.197-.822.823-1.914 1.553-3.199 1.6-1.902.074-5.062-.418-8.19-3.548C.425 9.317-.066 6.158.005 4.255c.049-1.285.78-2.377 1.6-3.199zm4 1.166c-.548-.982-2.02-1.207-3.03-.195-.709.708-1.17 1.49-1.2 2.28-.06 1.587.332 4.354 3.147 7.168 2.817 2.817 5.583 3.209 7.17 3.149.788-.03 1.572-.492 2.28-1.2 1.01-1.011.786-2.484-.196-3.031l-1.064-.594c-.66-.368-1.59-.243-2.242.41-.064.064-.472.444-1.196.479-.74.037-1.637-.296-2.651-1.311-1.016-1.015-1.349-1.912-1.312-2.654.034-.723.416-1.131.479-1.195.653-.652.778-1.581.41-2.242l-.595-1.064z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>
                        +{profile.data.country_code} {profile.data.mobile}
                        <button
                          type="button"
                          class="btn btn-outline-light "
                          onClick={() => setchangeMobileNumber(true)}
                        >
                          <svg
                            className="profile-account-mobile-img-edit-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                            width="30"
                            height="30"
                            fill="#0c768a"
                          >
                            <path d="M13.75 11.664l-3.457 3.457A3.02 3.02 0 018.172 16H8v-.172c0-.789.32-1.562.879-2.121l3.457-3.457 1.414 1.414zm1.043-3.871L13.75 8.836l1.414 1.414 1.043-1.043a1 1 0 00-1.414-1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-6.379-5.621a3.002 3.002 0 00-4.242 0l-5.914 5.914A4.968 4.968 0 006 15.828V17a1 1 0 001 1h1.172a4.968 4.968 0 003.535-1.465l5.914-5.914a3.002 3.002 0 000-4.242z"></path>
                          </svg>
                        </button>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {profile.data.kyc_status == 3 ? (
              <div className="profile-account-header-right-sec">
                <div className="profile-account-header-kyc-verified-btn">
                  <Image
                    className="kyc-verified-icon"
                    src={window.location.origin + "/img/kyc-verified.svg"}
                    type="image/png"
                  />
                </div>
                <h5>{t("kyc_verified")}</h5>
              </div>
            ) : (
              <div className="profile-account-header-kyc-verified-btn">
                <Button
                  className="efi-auth-btn"
                  onClick={() => setVerifyKYC(true)}
                >
                  {t("verify_kyc")}
                </Button>
              </div>
            )}
          </div>
          <div className="profile-account-body-sec">
            <div className="profile-account-list-box">
              <div className="profile-account-list-card">
                <div className="profile-account-list-details-sec">
                  <div className="profile-account-list-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 21 24"
                    >
                      <path
                        stroke="#252525"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M1 22.313v-1.184a7.104 7.104 0 017.104-7.105h4.736a7.104 7.104 0 017.104 7.104v1.184M10.472 10.472a4.736 4.736 0 110-9.472 4.736 4.736 0 010 9.472z"
                      ></path>
                    </svg>
                  </div>
                  <div className="profile-account-list-info">
                    <h4>{t("api_key")}</h4>
                    <p>***************</p>
                  </div>
                </div>
                <div
                  className="profile-account-list-btn-sec"
                  style={{ display: "flex", gap: "1em" }}
                >
                  {/* {profile.data.api_key_generated ? ( */}
                    <Button
                      className="efi-auth-btn"
                      onClick={() => setApiKeyModal(1)}
                    >
                      {t("view")}
                    </Button>
                  {/* ) : null} */}
                  {/* <Button
                    className="efi-auth-btn"
                    onClick={() => setApiKeyModal(2)}
                  >
                    {profile.data.api_key_generated
                      ? t("regenerate")
                      : t("generate")}
                  </Button> */}
                </div>
              </div>
              <div className="profile-account-list-card">
                <div className="profile-account-list-details-sec">
                  <div className="profile-account-list-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 21 24"
                    >
                      <path
                        stroke="#252525"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M1 22.313v-1.184a7.104 7.104 0 017.104-7.105h4.736a7.104 7.104 0 017.104 7.104v1.184M10.472 10.472a4.736 4.736 0 110-9.472 4.736 4.736 0 010 9.472z"
                      ></path>
                    </svg>
                  </div>
                  <div className="profile-account-list-info">
                    <h4>{t("salt_key")}</h4>
                    <p>***************</p>
                  </div>
                </div>
                <div
                  className="profile-account-list-btn-sec"
                  style={{ display: "flex", gap: "1em" }}
                >
                  <Button
                    className="efi-auth-btn"
                    onClick={() => setApiKeyModal(2)}
                  >
                    {t("view")}
                  </Button>
                </div>
              </div>
              <div className="profile-account-list-card">
                <div className="profile-account-list-details-sec">
                  <div className="profile-account-list-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 21 24"
                    >
                      <path
                        stroke="#252525"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M1 22.313v-1.184a7.104 7.104 0 017.104-7.105h4.736a7.104 7.104 0 017.104 7.104v1.184M10.472 10.472a4.736 4.736 0 110-9.472 4.736 4.736 0 010 9.472z"
                      ></path>
                    </svg>
                  </div>
                  <div className="profile-account-list-info">
                    <h4>{t("merchant_id")}</h4>
                    
                    <div className="payment-link-created-card">
                      <div className="payment-link-created-left-sec">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width={16} height={16}>
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                        </svg>
                        <span>
                          EFI00{zeroPad(profile.data.user_id, 3)}
                        </span>
                      </div>
                      <div className="payment-link-created-right-sec">
                        <CopyToClipboard
                          text={"EFI00"+zeroPad(profile.data.user_id, 3)}
                          onCopy={() => getSuccessNotificationMessage(t("merchant_id_copied_to_clipboard"))}>
                          <Button className="copy-btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              fill="#5C9EEB"
                              height="20"
                              viewBox="0 0 24 24"
                            >
                              <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                            </svg>
                            <span>{t("copy")}</span>
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="profile-account-list-card">
                <div className="profile-account-list-details-sec">
                  <div className="profile-account-list-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 21 24"
                    >
                      <path
                        stroke="#252525"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M1 22.313v-1.184a7.104 7.104 0 017.104-7.105h4.736a7.104 7.104 0 017.104 7.104v1.184M10.472 10.472a4.736 4.736 0 110-9.472 4.736 4.736 0 010 9.472z"
                      ></path>
                    </svg>
                  </div>
                  <div className="profile-account-list-info">
                    <h4>{t("username")}</h4>
                    <p>{profile.data.name}</p>
                  </div>
                </div>
                <div className="profile-account-list-btn-sec">
                  <Button
                    className="efi-auth-btn"
                    onClick={() => setChangeUserName(true)}
                  >
                    {t("edit")}
                  </Button>
                </div>
              </div>
              <div className="profile-account-list-card">
                <div className="profile-account-list-details-sec">
                  <div className="profile-account-list-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 21 19"
                    >
                      <path
                        fill="#020E30"
                        d="M16.625.313H4.375a4.54 4.54 0 00-3.092 1.2C.463 2.278 0 3.318 0 4.402v9.819c.001 1.084.463 2.124 1.283 2.89a4.54 4.54 0 003.092 1.2h12.25a4.54 4.54 0 003.092-1.2c.82-.766 1.282-1.806 1.283-2.89V4.403c-.001-1.084-.463-2.124-1.283-2.89a4.54 4.54 0 00-3.092-1.2zM4.375 1.948h12.25a2.755 2.755 0 011.47.424c.433.275.768.665.963 1.12l-6.701 6.266c-.493.46-1.161.717-1.857.717a2.727 2.727 0 01-1.857-.717l-6.7-6.266c.194-.455.53-.845.963-1.12a2.755 2.755 0 011.469-.424zm12.25 14.727H4.375a2.72 2.72 0 01-1.856-.719 2.376 2.376 0 01-.769-1.735V5.63l5.656 5.285a4.54 4.54 0 003.094 1.196 4.54 4.54 0 003.094-1.196l5.656-5.285v8.59c0 .652-.277 1.276-.769 1.736a2.72 2.72 0 01-1.856.72z"
                      ></path>
                    </svg>
                  </div>
                  <div className="profile-account-list-info">
                    <h4>{t("change_email_address")}</h4>
                    <p>{profile.data.email}</p>
                  </div>
                </div>
                <div className="profile-account-list-btn-sec">
                  <Button
                    className="efi-auth-btn"
                    onClick={() => setChangeEmailAddress(true)}
                  >
                    {t("edit")}
                  </Button>
                </div>
              </div>
              <div className="profile-account-list-card">
                <div className="profile-account-list-details-sec">
                  <div className="profile-account-list-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 20 25"
                    >
                      <path
                        fill="#000"
                        d="M17.949 2.947L10 .313 2.051 2.947A2.982 2.982 0 000 5.78v6.524c0 7.524 9.2 11.68 9.594 11.852l.354.157.368-.122C10.711 24.058 20 20.88 20 12.303V5.78a2.984 2.984 0 00-2.051-2.832zM18 12.304c0 6.263-6.349 9.216-7.953 9.86C8.44 21.361 2 17.797 2 12.305V5.778a.994.994 0 01.684-.944L10 2.41l7.316 2.425A.992.992 0 0118 5.78v6.524z"
                      ></path>
                    </svg>
                  </div>
                  <div className="profile-account-list-info">
                    <h4>{t("change_password")}</h4>
                    <p>*******************</p>
                  </div>
                </div>
                <div className="profile-account-list-btn-sec">
                  <Button
                    className="efi-auth-btn"
                    onClick={() => setChangePassword(true)}
                  >
                    {t("edit")}
                  </Button>
                </div>
              </div>
              <div className="profile-account-list-card">
                <div className="profile-account-list-details-sec">
                  <div className="profile-account-list-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#000"
                        d="M9.813.313A9.752 9.752 0 000 10.124a9.752 9.752 0 009.813 9.813 9.752 9.752 0 009.812-9.813A9.752 9.752 0 009.812.312zm0 18.223c-4.626 0-8.411-3.785-8.411-8.411 0-4.626 3.785-8.41 8.41-8.41 4.626 0 8.411 3.784 8.411 8.41s-3.785 8.41-8.41 8.41z"
                      ></path>
                      <path
                        fill="#000"
                        d="M13.597 15.031l-3.784-3.785-3.785 3.785-1.122-1.121 3.785-3.785L4.906 6.34 6.028 5.22l3.785 3.785 3.784-3.785L14.72 6.34l-3.785 3.785 3.785 3.785-1.122 1.121z"
                      ></path>
                    </svg>
                  </div>
                  <div className="profile-account-list-info">
                    <h4>{t("delete_your_account")}</h4>
                    <p>{t("if_you_want_to_stop_using_inefipay")}</p>
                  </div>
                </div>
                <div className="profile-account-list-btn-sec">
                  <Button
                    className="efi-auth-btn"
                    onClick={() => setDeleteAccount(true)}
                  >
                    {t("delete")}
                  </Button>
                </div>
              </div>
              <div className="profile-account-list-card">
                <div className="profile-account-list-details-sec">
                  <div className="profile-account-list-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 19 20"
                    >
                      <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7.594 10.137h9.652m0 0L14.488 7.59m2.758 2.546l-2.758 2.545M16 16.17a8.785 8.785 0 01-4.455 2.415c-1.7.355-3.47.208-5.083-.422a8.669 8.669 0 01-3.968-3.113A8.29 8.29 0 011 10.312a8.29 8.29 0 011.494-4.737 8.669 8.669 0 013.968-3.113 8.955 8.955 0 015.083-.422A8.785 8.785 0 0116 4.455"
                      ></path>
                    </svg>
                  </div>
                  <div className="profile-account-list-info">
                    <h4>{t("logout_of_all_device")}</h4>
                    <p>{t("if_you_notice_any_para")}</p>
                  </div>
                </div>
                <div className="profile-account-list-btn-sec">
                  <Button className="efi-auth-btn" onClick={logout}>
                    {t("logout")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {changeUserName && (
        <ChangeUserNameModal
          profile={profile}
          changeUserName={changeUserName}
          closeChangeUserNameModal={closeChangeUserNameModal}
          setChangeUserName={setChangeUserName}
        />
      )}
      {changePassword && (
        <ChangePasswordModal
          profile={profile}
          changePassword={changePassword}
          closeChangePasswordModal={closeChangePasswordModal}
          setChangePassword={setChangePassword}
        />
      )}
      {changeMobileNumber && (
        <ChangeMobileNumberModal
          profile={profile}
          changeMobileNumber={changeMobileNumber}
          closeChangeMobileNumberModal={closeChangeMobileNumberModal}
          setchangeMobileNumber={setchangeMobileNumber}
        />
      )}

      {changeEmailAddress && (
        <ChangeEmailAddressModal
          profile={profile}
          setNewEmail={setNewEmail}
          changeEmailAddress={changeEmailAddress}
          closeChangeEmailAddressModal={closeChangeEmailAddressModal}
          setChangeEmailAddress={setChangeEmailAddress}
        />
      )}
      {deleteAccount && (
        <DeleteAccountModal
          deleteAccount={deleteAccount}
          closeDeleteAccountModal={closeDeleteAccountModal}
          setDeleteAccount={setDeleteAccount}
        />
      )}
      {apiKeyModal && (
        <ApiKeyModal
          apiKeyModal={apiKeyModal}
          closeApiKeyModal={closeApiKeyModal}
          setApiKeyModal={setApiKeyModal}
        />
      )}
      {verifyKYC && (
        <VerifyKYCModal
          verifyKYC={verifyKYC}
          closeVerifyKYCModal={closeVerifyKYCModal}
          setVerifyKYC={setVerifyKYC}
        />
      )}
      {showVerify && (
        <OTPVerificationModal
          forgotPassword={2}
          showVerify={showVerify}
          closeShowVerify={() => setShowVerify(false)}
        />
      )}
    </>
  ) : (
    <SomethingWentWrong
      buttonText="Retry"
      handleClick={() => dispatch(profileStart())}
    />
  );
};

export default AccountIndex;
