import React, { useEffect, useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { transactionCancelStart } from "../../store/slices/PaymentSlice";
import { transactionCancelStart as traderTransactionCancelStart } from "../../store/slices/TraderSlice";
import { useTranslation } from "react-multi-lang";

const PaymentCancel = (props) => {
  const t = useTranslation("payment_cancel")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const transactionView = useSelector((state) => state.payment.transactionView);
  const transactionCancel = useSelector(
    (state) => state.payment.transactionCancel
  );
  const traderTransactionCancel = useSelector(
    (state) => state.trader.transactionCancel
  );
  const checkPaymentMethod = useSelector((state) => state.trader.checkPaymentMethod);

  const [skiprender, setSkipRender] = useState(true);

  const handleCancel = () => {
    if (transactionView.data.transaction.payment_type == "inr" && Object.keys(checkPaymentMethod.data).length > 0) {
      dispatch(
        traderTransactionCancelStart({
          order_id: transactionView.data.transaction.order_id,
          status: 4,
        })
      );
    } else {
      dispatch(
        transactionCancelStart({
          transaction_unique_id: transactionView.data.transaction.unique_id,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !skiprender &&
      !transactionCancel.loading &&
      Object.keys(transactionCancel.data).length > 0
    ) {
      transactionView.data.cancel_url
        ? window.location.assign(transactionView.data.cancel_url)
        : navigate(-1);
    }
  }, [transactionCancel]);

  useEffect(() => {
    if (
      !skiprender &&
      !traderTransactionCancel.loading &&
      Object.keys(traderTransactionCancel.data).length > 0
    ) {
      dispatch(
        transactionCancelStart({
          transaction_unique_id: transactionView.data.transaction.unique_id,
        })
      );
    }
    setSkipRender(false);
  }, [traderTransactionCancel]);

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="payment-cancel-wrapped">
            <Image
              className=""
              src={ transactionView.data.payment_page.cancel_image || window.location.origin + "/img/payment-cancel-icon.png"}
              type="image/png"
            />
            <h3>{t("heading")}</h3>
            <p>{t("message")}</p>
            <div className="pay-cancel-btn">
              <Button
                className="cancel-pay"
                onClick={() => handleCancel()}
                disabled={transactionCancel.buttonDisable}
              >
                {transactionCancel.buttonDisable ? t("loading") :`${t("yes")}, ${t("cancel")}`}
              </Button>
              <Button className="no-pay" onClick={props.handleClose}>
              {t("no")}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentCancel;
