import React, { useEffect } from "react";
import { setLanguage } from "react-multi-lang";
import Select from "react-select";

const EmptyHeader = (props) => {

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      background: "linear-gradient(89.12deg, #3d8b9c 0.53%, #1d9bb3 96.47%)!important",
      border: "none!important",
      borderRadius: "0!important",
      boxShadow: "none!important",
      height: "45px",
      cursor:"pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#000!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#000!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#000",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
        '&:active': { backgroundColor: isSelected ? "#f7f7f7" : "#fff" }
      };
    },
  };

  const [languageOption, setLanguageOption] = React.useState(null);

  const options = [
    { value: "en", label: "English" },
    { value: "es", label: "Spanish" },
    { value: "arb", label: "Arabic" },
  ]

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      const selectedOption = options.find(option => option.value === lang);
      setLanguageOption(selectedOption);
      setLanguage(lang);
    } else {
      setLanguage(options[0].value);
      setLanguageOption(options[0]);
    }
  }, [])

  return (
    <>
      {/* <header className="auth-header"> */}
        {/* <Select
          options={options}
          styles={customStyles}
          isSearchable={false}
          value={languageOption}
          placeholder={<div>Language</div>}
          onChange={(selectedOption) => {
            setLanguageOption(selectedOption);
            setLanguage(selectedOption.value);
            localStorage.setItem("lang", selectedOption.value);
          }}
        /> */}
      {/* </header> */}
    </>
  );
};

export default EmptyHeader;
