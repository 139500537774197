import React, { useState } from "react";
import {
  Image,
  Button,
  InputGroup,
  Label,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import "./PaymentLayout.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

const PaymentError = (props) => {
  const t = useTranslation("payment_error")
  const navigate = useNavigate();
  const transactionView = useSelector((state) => state.payment.transactionView);

  return (
    <>
      <div className="session-sec-wraped">
        {props.error == "session-expired" ? (
          <>
            <Image
              className="session-icon"
              src={Object.keys(transactionView.data).length > 0 && Object.keys(transactionView.data.payment_page).length > 0 ? transactionView.data.payment_page.transaction_failed_image : window.location.origin + "/img/session-exp-img.png"}
              type="image/png"
            />
            <h4>{t("heading")}</h4>
            <p>
              {t("session_expired")}
            </p>
            <Button
              className="btn-next"
              type="submit"
              onClick={() => navigate(-1)}
            >
              {t("website")}
            </Button>
          </>
        ) : props.error == "transaction-processed" ? (
          <>
            <Image
              className="session-icon"
              src={transactionView.data.payment_page.transaction_failed_image || window.location.origin + "/img/session-exp-img.png"}
              type="image/png"
            />
            <h4>{t("processed")}</h4>
            <p>
              {t("already_processed")}
            </p>
            <Button
              className="btn-next"
              type="submit"
              onClick={() => navigate(-1)}
            >
              {t("website")}
            </Button>
          </>
        ) : props.error == "payment-failed" ? (
          <>
            <Image
              className="session-icon"
              src={transactionView.data.payment_page.transaction_failed_image || window.location.origin + "/img/session-exp-img.png"}
              type="image/png"
            />
            <h4>{t("payemnt_failed")}</h4>
            <p>
              {t("we_are_sorry")}
            </p>
            <Button
              className="btn-next"
              type="submit"
              onClick={() => Object.keys(transactionView.data).length && transactionView.data.failure_url > 0 ? navigate(-1) : window.location.assign(transactionView.data.failure_url)}
            >
              {t("website")}
            </Button>
          </>
        ) : props.error == "payment-cancelled" ? (
          <>
            <Image
              className="session-icon"
              src={transactionView.data.payment_page.transaction_failed_image || window.location.origin + "/img/session-exp-img.png"}
              type="image/png"
            />
            <h4>{t("payment_cancelled")}</h4>
            <p>
              {t("we_apologize")}
            </p>
            <Button
              className="btn-next"
              type="submit"
              onClick={() => Object.keys(transactionView.data).length && transactionView.data.cancel_url > 0 ? navigate(-1) : window.location.assign(transactionView.data.cancel_url)}
            >
              {t("website")}
            </Button>
          </>
        ) : (
          <>
            <Image
              className="session-icon"
              src={transactionView.data.payment_page.transaction_failed_image || window.location.origin + "/img/session-exp-img.png"}
              type="image/png"
            />
            <h4>{props.errorMessage ? props.errorMessage : t("invalid")}</h4>
            <p>
              {t("transaction_invalid")}
            </p>
            <Button
              className="btn-next"
              type="submit"
              onClick={() => navigate(-1)}
            >
              {t("website")}
            </Button>{" "}
          </>
        )}
      </div>
    </>
  );
};

export default PaymentError;
