import React, { useState, useEffect } from 'react';

const TimeElapsed = ({ timestamp }) => {
  const [elapsedTime, setElapsedTime] = useState('');

  useEffect(() => {
    const calculateTimeElapsed = () => {
      const now = new Date();
      const messageTime = new Date(timestamp); // Timestamp of the message

      const difference = now - messageTime; // Difference in milliseconds

      const seconds = Math.floor(difference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30); // Assuming a month has 30 days
      const years = Math.floor(days / 365); // Assuming a year has 365 days

      if (years > 0) {
        setElapsedTime(`${years} year${years !== 1 ? 's' : ''} ago`);
      } else if (months > 0) {
        setElapsedTime(`${months} month${months !== 1 ? 's' : ''} ago`);
      } else if (days > 0) {
        setElapsedTime(`${days} day${days !== 1 ? 's' : ''} ago`);
      } else if (hours > 0) {
        setElapsedTime(`${hours} hour${hours !== 1 ? 's' : ''} ago`);
      } else if (minutes > 0) {
        setElapsedTime(`${minutes} minute${minutes !== 1 ? 's' : ''} ago`);
      } else {
        setElapsedTime(`${seconds} second${seconds !== 1 ? 's' : ''} ago`);
      }
    };

    calculateTimeElapsed(); // Initial calculation

    // Update the elapsed time every second
    const intervalId = setInterval(calculateTimeElapsed, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [timestamp]);

  return <p>{elapsedTime}</p>;
};

export default TimeElapsed;
