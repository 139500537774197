import React, { useState } from "react";
import { Form, Button, InputGroup, Image, Modal } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../PaymentLayout.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PaymentCancel from "../PaymentCancel";
import { useTranslation } from "react-multi-lang";

const PayOutOne = (props) => {
const t = useTranslation("payout_one")
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const transactionView = useSelector((state) => state.payment.transactionView);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("invalid")).required(t("required")),
  });

  const handleSubmit = (values) => {
    if(values.email){
      props.setEmail(values.email)
      props.setStep(2);
    }
  };

  return (
    <>
      <div className="efi-payment-form-wrap">
        <div className="efi-form-wrap-top">
          <div className="form-heading">
            <h2>{t("heading")}</h2>
          </div>
          <div className="efi-total-amount-frame">
            <p>{t("amount")}</p>
            <h3>{props.transaction.formatted_amount}</h3>
          </div>
        </div>
        <div className="efi-form-wrap-middle">
          <div className="efi-pay-method-info">
            <p>{t("payment_method")}</p>
            <h4>{props.transaction.payment_type_formatted}</h4>
          </div>
          <div className="efi-pay-method-info">
            <p>{t("order_id")}</p>
            <h4>{props.transaction.order_id}</h4>
          </div>
        </div>
        <div className="efi-form-wrap-bottom">
          <Formik
            initialValues={{
              email: transactionView.data.transaction.user_email,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <FORM>
                <div className="form-group-frame">
                  <Form.Label>{t("email_id")}</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="email-input">
                      <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Group 1815">
                          <path
                            id="Vector"
                            d="M18 10.7835C18 13.1084 15.984 14.9917 13.473 15H13.464H4.545C2.043 15 0 13.1251 0 10.8001V10.7918C0 10.7918 0.00539994 7.1036 0.0125999 5.24864C0.0134999 4.90032 0.4455 4.70533 0.7398 4.92199C2.8782 6.49278 6.7023 9.35685 6.75 9.39435C7.389 9.8685 8.199 10.136 9.027 10.136C9.855 10.136 10.665 9.8685 11.304 9.38518C11.3517 9.35602 15.0903 6.57778 17.2611 4.98115C17.5563 4.76366 17.9901 4.95865 17.991 5.30614C18 7.14693 18 10.7835 18 10.7835Z"
                            fill="#D3D5D7"
                          />
                          <path
                            id="Vector_2"
                            d="M17.5283 2.22826C16.7489 0.868303 15.2153 0 13.5269 0H4.5449C2.8565 0 1.3229 0.868303 0.543495 2.22826C0.368895 2.53241 0.451695 2.91157 0.742395 3.12656L7.42491 8.07558C7.89291 8.42556 8.45991 8.59972 9.02691 8.59972H9.03591H9.04491C9.61191 8.59972 10.1789 8.42556 10.6469 8.07558L17.3294 3.12656C17.6201 2.91157 17.7029 2.53241 17.5283 2.22826Z"
                            fill="#D3D5D7"
                          />
                        </g>
                      </svg>
                    </InputGroup.Text>
                    <Field
                      name="email"
                      type="email"
                      className="form-control"
                      value={values.email}
                      autoFocus={true}
                    />
                  </InputGroup>
                  <ErrorMessage
                    component={"div"}
                    name="email"
                    className="errorMsg"
                  />
                </div>
                <div className="form-wrap-btn">
                  <Button type="button" className="btn-cancel" onClick={handleShow}>
                  {t("cancel")}
                  </Button>
                  <Button type="submit" className="btn-next">
                  {t("next")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
          {show && <PaymentCancel show={show} handleClose={handleClose} />}
        </div>
      </div>
    </>
  );
};

export default PayOutOne;
