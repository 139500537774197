import React, { useEffect, useState } from "react";
import { Table, Modal, Image } from "react-bootstrap";
import FsLightbox from "fslightbox-react";
import { useTranslation } from "react-multi-lang";

const ViewInrPayoutModal = (props) => {

  const t = useTranslation("payout_transactions")
  const [toggler, setToggler] = useState(false);

  const getStatusClass = (status) => {
    switch (status) {
      case 0:
        return "payment-initiated";
      case 1:
        return "payment-paid";
      case 2:
        return "payment-onhold";
      case 3:
        return "payment-rejected";
      case 4:
        return "payment-cancelled";
      default:
        return "payment-initiated";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 0:
        return "#5C9EEB";
      case 1:
        return "#197E23";
      case 2:
        return "#FFCE22";
      case 3:
        return "#FF6A6A";
      case 4:
        return "#FF8A00";
      default:
        return "#5C9EEB";
    }
  };

  console.log("show", props.show)

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="view-transaction-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Payout Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="view-transaction-table">
          <Table>
            <tbody>
              <tr>
                <td>{t("settlement_table.payment_id")}</td>
                <td>{props.show.requestId}</td>
              </tr>
              <tr>
                <td>{t("amount")}</td>
                <td>
                  <div className="transaction-amount">
                    {props.show.amount_formatted}
                  </div>
                </td>
              </tr>
              <tr>
                <td>{t("beneficiaryAccount.name")}</td>
                <td>{props.show.beneficiaryAccount}</td>
              </tr>
              <tr>
                <td>{t("beneficiaryAddress.name")}</td>
                <td>{props.show.beneficiaryAddress}</td>
              </tr>
              <tr>
                <td>{t("beneficiaryIFSC.name")}</td>
                <td>{props.show.beneficiaryIFSC}</td>
              </tr>
              <tr>
                <td>{t("beneficiaryName.name")}</td>
                <td>{props.show.beneficiaryName}</td>
              </tr>
              <tr>
                <td>{t("paymentMode.name")}</td>
                <td>{props.show.paymentMode}</td>
              </tr>
              <tr>
                <td>{t("status")}</td>
                <td style={{display:"flex", justifyContent:"flex-end"}}>
                  <div className={getStatusClass(props.show.status_formatted)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      fill="#5C9EEB"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0z"></path>
                    </svg>
                    {props.show.status_formatted}
                  </div>
                </td>
              </tr>
              <tr>
                <td>{t("created_at")}</td>
                <td>{props.show.created_at}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewInrPayoutModal;
