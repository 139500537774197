import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga, commonSagaGetApi, getTraderCommonSaga, commonSagaTradedGetApi, chatCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  inrPayoutDetailsFailure,
  inrPayoutDetailsSuccess,
  inrPayoutTransactionsListFailure,
  inrPayoutTransactionsListSuccess,
  zyroPayoutBalanceFailure,
  zyroPayoutBalanceSuccess,
  zyroPayoutTransactionsFailure,
  zyroPayoutTransactionsSuccess,
  requestZyroPayOutFailure,
  requestZyroPayOutSuccess,
  initiateDepositFailure,
  initiateDepositSuccess,
  payoutDepositFailure,
  payoutDepositSuccess,
  payoutDepositViewFailure,
  payoutDepositViewSuccess,
  exportDepositFailure,
  exportDepositSuccess,
  initiateWithdrawalSuccess,
  initiateWithdrawalFailure,
  withdrawalTransactionsSuccess,
  withdrawalTransactionsFailure,
  withdrawalTransactionsViewSuccess,
  withdrawalTransactionsViewFailure,
  exportWithdrawalTransactionsSuccess,
  exportWithdrawalTransactionsFailure,
  walletSuccess,
  walletFailure,
  payOutBankSuccess,
  payOutBankFailure,
  importPayoutRequestSuccess,
  importPayoutRequestFailure,
  chatSupportMessageSuccess,
  chatSupportMessageFailure,
  chatSupportMessageReadSuccess,
  chatSupportMessageReadFailure,
  chatSupportUnreadListSuccess,
  chatSupportUnreadListFailure,
  chatSupportMessagesSuccess,
  chatSupportMessagesFailure

} from "../slices/PayoutSlice";


function* inrPayoutTransactionsAPI(action) {
  yield getCommonSaga({
    apiUrl: "inr_payout_transactions_list",
    payload: action.payload,
    successNotify: false,
    success: inrPayoutTransactionsListSuccess,
    failure: inrPayoutTransactionsListFailure,
  });
}

function* inrPayoutDetailsAPI(action) {
  yield getCommonSaga({
    apiUrl: "inr_payout_details",
    payload: action.payload,
    successNotify: false,
    success: inrPayoutDetailsSuccess,
    failure: inrPayoutDetailsFailure,
  });
}

function* zyroPayoutTransactionsAPI(action) {
  yield getCommonSaga({
    apiUrl: "EfiPayProcess/GetTransactionHistory",
    payload: action.payload,
    successNotify: false,
    success: zyroPayoutTransactionsSuccess,
    failure: zyroPayoutTransactionsFailure,
  });
}

function* zyroPayoutBalanceAPI(action) {
  yield getCommonSaga({
    apiUrl: "EfiPayProcess/GetBalance",
    payload: action.payload,
    successNotify: false,
    success: zyroPayoutBalanceSuccess,
    failure: zyroPayoutBalanceFailure,
  });
}

function* requestZyroPayOutAPI(action) {
  const zyroPayoutTransactions = yield select(state => state.payout.zyroPayoutTransactions.data);
  yield getCommonSaga({
    apiUrl: "EfiPayProcess/FundTransfer",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(requestZyroPayOutSuccess({ data: data }));
      if (Object.keys(zyroPayoutTransactions).length > 0)
        store.dispatch(zyroPayoutTransactionsSuccess({
          ...zyroPayoutTransactions,
          inr_payout: [data.inr_payout, ...zyroPayoutTransactions.inr_payout],
          total_inr_payout: zyroPayoutTransactions.total_inr_payout + 1
        }))
    },
    failure: requestZyroPayOutFailure,
  });
}
function* payoutDepositAPI(action) {
  const payload = { ...action.payload, is_central: 1 }

  yield getTraderCommonSaga({
    apiUrl: "payout/deposits",
    payload: payload,
    successNotify: false,
    success: payoutDepositSuccess,
    failure: payoutDepositFailure,
  });
}

function* initiateDepositAPI(action) {
  const payoutTransactions = yield select(state => state.payout.payoutDeposit.data);
  yield getTraderCommonSaga({
    apiUrl: "payout/initiate_deposits",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(initiateDepositSuccess({ data: data }));
      if (Object.keys(payoutTransactions).length > 0)
        store.dispatch(payoutDepositSuccess({
          ...payoutTransactions,
          payout_deposits: [data.payout_deposit, ...payoutTransactions.payout_deposits],
          payout_deposits_count: payoutTransactions.payout_deposits_count + 1
        }))
    },
    failure: initiateDepositFailure,
  });
}

function* exportDepositAPI(action) {
  yield getTraderCommonSaga({
    apiUrl: "payout/export_deposits",
    payload: action.payload,
    successNotify: false,
    success: exportDepositSuccess,
    failure: exportDepositFailure,
  });
}

function* payoutDepositViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "payout/deposit_view",
    payload: action.payload,
    successNotify: false,
    success: payoutDepositViewSuccess,
    failure: payoutDepositViewFailure,
  });
}
function* walletAPI(action) {
  const payload = { ...action.payload, is_central: 1 }
  yield getTraderCommonSaga({
    apiUrl: "whitelabel_merchant/wallet",
    payload: payload,
    successNotify: false,
    success: walletSuccess,
    failure: walletFailure,
  });
}

function* withdrawalTransactionAPI(action) {
  yield getTraderCommonSaga({
    apiUrl: "whitelabel_merchant/withdrawal_transactions",
    payload: action.payload,
    successNotify: false,
    success: withdrawalTransactionsSuccess,
    failure: withdrawalTransactionsFailure,
  });
}

function* initiateWithdrawalAPI(action) {
  const payoutTransactions = yield select(state => state.payout.withdrawalTransactions.data);
  yield getTraderCommonSaga({
    apiUrl: "EfiPayProcess/FundTransfer",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
      success: data => {
        store.dispatch(initiateWithdrawalSuccess({ data: data }));
        if (Object.keys(payoutTransactions).length > 0)
          store.dispatch(withdrawalTransactionsSuccess({
            ...payoutTransactions,
            merchant_payments: [data.transaction,...payoutTransactions.merchant_payments],
            merchant_payments_count: payoutTransactions.merchant_payments_count + 1
          }))
      },
    failure: initiateWithdrawalFailure,
  });
}

function* exportWithdrawalTransactionAPI(action) {
  yield getTraderCommonSaga({
    apiUrl: "whitelabel_merchant/export_withdrawal_transactions",
    payload: action.payload,
    successNotify: false,
    success: exportWithdrawalTransactionsSuccess,
    failure: exportWithdrawalTransactionsFailure,
  });
}

function* WithdrawalTransactionsViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "payout/withdrawal_transaction_view",
    payload: action.payload,
    successNotify: false,
    success: withdrawalTransactionsViewSuccess,
    failure: withdrawalTransactionsViewFailure,
  });
}

function* payOutBankAPI(action) {
  const payload = { ...action.payload, is_central: 1 }
  yield commonSagaTradedGetApi({
    apiUrl: "payout/banks",
    payload: payload,
    successNotify: false,
    success: payOutBankSuccess,
    failure: payOutBankFailure,
  });
}

function* importPayoutRequestAPI(action) {  
  yield getTraderCommonSaga({
    apiUrl: "whitelabel_merchant/import_payout_request",
    payload: action.payload,
    successNotify: true,
    success: importPayoutRequestSuccess,
    failure: importPayoutRequestFailure,
  });
}

function* chatSupportMessageAPI(action) {  
  yield chatCommonSaga({
    apiUrl: "chats_messages/store",
    payload: action.payload,
    successNotify: false,
    success: chatSupportMessageSuccess,
    failure: chatSupportMessageFailure,
  });
}

function* chatSupportMessageReadAPI(action) {  
  yield chatCommonSaga({
    apiUrl: "chats_messages/read",
    payload: action.payload,
    successNotify: false,
    success: chatSupportMessageReadSuccess,
    failure: chatSupportMessageReadFailure,
  });
}

function* chatSupportUnreadListAPI(action) {  
  yield chatCommonSaga({
    apiUrl: "chats_messages/un_read",
    payload: action.payload,
    successNotify: false,
    success: chatSupportUnreadListSuccess,
    failure: chatSupportUnreadListFailure,
  });
}

function* chatSupportMessagesAPI(action) {  
  yield chatCommonSaga({
    apiUrl: "chats_messages",
    payload: action.payload,
    successNotify: false,
    success: chatSupportMessagesSuccess,
    failure: chatSupportMessagesFailure,
  });
}

export default function* PayoutSaga() {
  yield all([
    yield takeLatest('payout/inrPayoutTransactionsListStart', inrPayoutTransactionsAPI),
    yield takeLatest('payout/inrPayoutDetailsStart', inrPayoutDetailsAPI),
    yield takeLatest('payout/zyroPayoutTransactionsStart', zyroPayoutTransactionsAPI),
    yield takeLatest('payout/zyroPayoutBalanceStart', zyroPayoutBalanceAPI),
    yield takeLatest('payout/requestZyroPayOutStart', requestZyroPayOutAPI),
    yield takeLatest('payout/payoutDepositStart', payoutDepositAPI),
    yield takeLatest('payout/initiateDepositStart', initiateDepositAPI),
    yield takeLatest('payout/exportDepositStart', exportDepositAPI),
    yield takeLatest('payout/payoutDepositViewStart', payoutDepositViewAPI),
    yield takeLatest('payout/walletStart', walletAPI),
    yield takeLatest('payout/withdrawalTransactionsStart', withdrawalTransactionAPI),
    yield takeLatest('payout/initiateWithdrawalStart', initiateWithdrawalAPI),
    yield takeLatest('payout/exportWithdrawalTransactionsStart', exportWithdrawalTransactionAPI),
    yield takeLatest('payout/withdrawalTransactionsViewStart', WithdrawalTransactionsViewAPI),
    yield takeLatest('payout/payOutBankStart', payOutBankAPI),
    yield takeLatest('payout/importPayoutRequestStart', importPayoutRequestAPI),
    yield takeLatest('payout/chatSupportMessageStart', chatSupportMessageAPI),
    yield takeLatest('payout/chatSupportMessageReadStart', chatSupportMessageReadAPI),
    yield takeLatest('payout/chatSupportUnreadListStart', chatSupportUnreadListAPI),
    yield takeLatest('payout/chatSupportMessagesStart', chatSupportMessagesAPI),
  ]);
}

