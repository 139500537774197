import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonFailure,
    getCommonSuccess,
    getCommonStart
} from "../utils"

const initialState = {
    inrPayoutTransactionsList: getCommonInitialState(),
    inrPayoutDetails: getCommonInitialState(),
    zyroPayoutBalance: getCommonInitialState(),
    zyroPayoutTransactions: getCommonInitialState(),
    requestZyroPayOut: getCommonInitialState(),
    initiateDeposit: getCommonInitialState(),
    payoutDeposit: getCommonInitialState(),
    payoutDepositView: getCommonInitialState(),
    exportDeposit:getCommonInitialState(),
    wallet:getCommonInitialState(),
    initiateWithdrawal: getCommonInitialState(),
    withdrawalTransactions: getCommonInitialState(),
    withdrawalTransactionsView: getCommonInitialState(),
    exportWithdrawalTransactions:getCommonInitialState(),
    payOutBank:getCommonInitialState(),
    importPayoutRequest:getCommonInitialState(),
    chatSupportMessage: getCommonInitialState(),
    chatSupportMessageRead: getCommonInitialState(),
    chatSupportUnreadList: getCommonInitialState(),
    chatSupportMessages: getCommonInitialState(),
    activeChatSupport: {},
};

const PayoutSlice = createSlice({
    name: "payout",
    initialState,
    reducers: {

        inrPayoutTransactionsListStart: (state) => { state.inrPayoutTransactionsList = getCommonStart()},
        inrPayoutTransactionsListSuccess: (state, action) => { state.inrPayoutTransactionsList = getCommonSuccess(action.payload)},
        inrPayoutTransactionsListFailure: (state, action) => { state.inrPayoutTransactionsList = getCommonFailure(action.payload)},

        inrPayoutDetailsStart: (state) => { state.inrPayoutDetails = getCommonStart()},
        inrPayoutDetailsSuccess: (state, action) => { state.inrPayoutDetails = getCommonSuccess(action.payload)},
        inrPayoutDetailsFailure: (state, action) => { state.inrPayoutDetails = getCommonFailure(action.payload)},

        zyroPayoutBalanceStart: (state) => { state.zyroPayoutBalance = getCommonStart()},
        zyroPayoutBalanceSuccess: (state, action) => { state.zyroPayoutBalance = getCommonSuccess(action.payload)},
        zyroPayoutBalanceFailure: (state, action) => { state.zyroPayoutBalance = getCommonFailure(action.payload)},

        zyroPayoutTransactionsStart: (state) => { state.zyroPayoutTransactions = getCommonStart()},
        zyroPayoutTransactionsSuccess: (state, action) => { state.zyroPayoutTransactions = getCommonSuccess(action.payload)},
        zyroPayoutTransactionsFailure: (state, action) => { state.zyroPayoutTransactions = getCommonFailure(action.payload)},

        requestZyroPayOutStart: (state) => { state.requestZyroPayOut = getCommonStart() },
        requestZyroPayOutSuccess: (state, action) => { state.requestZyroPayOut = getCommonSuccess(action.payload) },
        requestZyroPayOutFailure: (state, action) => { state.requestZyroPayOut = getCommonFailure(action.payload) },
        
        initiateDepositStart: (state) => { state.initiateDeposit = getCommonStart()},
        initiateDepositSuccess: (state, action) => { state.initiateDeposit = getCommonSuccess(action.payload)},
        initiateDepositFailure: (state, action) => { state.initiateDeposit = getCommonFailure(action.payload)},

        payoutDepositStart: (state) => { state.payoutDeposit = getCommonStart()},
        payoutDepositSuccess: (state, action) => { state.payoutDeposit = getCommonSuccess(action.payload)},
        payoutDepositFailure: (state, action) => { state.payoutDeposit = getCommonFailure(action.payload)},

        payoutDepositViewStart: (state) => { state.payoutDepositView = getCommonStart()},
        payoutDepositViewSuccess: (state, action) => { state.payoutDepositView = getCommonSuccess(action.payload)},
        payoutDepositViewFailure: (state, action) => { state.payoutDepositView = getCommonFailure(action.payload)},

        exportDepositStart: (state) => { state.exportDeposit = getCommonStart()},
        exportDepositSuccess: (state, action) => { state.exportDeposit = getCommonSuccess(action.payload)},
        exportDepositFailure: (state, action) => { state.exportDeposit = getCommonFailure(action.payload)},

        initiateWithdrawalStart: (state) => { state.initiateWithdrawal = getCommonStart()},
        initiateWithdrawalSuccess: (state, action) => { state.initiateWithdrawal = getCommonSuccess(action.payload)},
        initiateWithdrawalFailure: (state, action) => { state.initiateWithdrawal = getCommonFailure(action.payload)},

        withdrawalTransactionsStart: (state) => { state.withdrawalTransactions = getCommonStart()},
        withdrawalTransactionsSuccess: (state, action) => { state.withdrawalTransactions = getCommonSuccess(action.payload)},
        withdrawalTransactionsFailure: (state, action) => { state.withdrawalTransactions = getCommonFailure(action.payload)},

        withdrawalTransactionsViewStart: (state) => { state.withdrawalTransactionsView = getCommonStart()},
        withdrawalTransactionsViewSuccess: (state, action) => { state.withdrawalTransactionsView = getCommonSuccess(action.payload)},
        withdrawalTransactionsViewFailure: (state, action) => { state.withdrawalTransactionsView = getCommonFailure(action.payload)},

        exportWithdrawalTransactionsStart: (state) => { state.exportWithdrawalTransactions = getCommonStart()},
        exportWithdrawalTransactionsSuccess: (state, action) => { state.exportWithdrawalTransactions = getCommonSuccess(action.payload)},
        exportWithdrawalTransactionsFailure: (state, action) => { state.exportWithdrawalTransactions = getCommonFailure(action.payload)},

        walletStart: (state) => { state.wallet = getCommonStart()},
        walletSuccess: (state, action) => { state.wallet = getCommonSuccess(action.payload)},
        walletFailure: (state, action) => { state.wallet = getCommonFailure(action.payload)},

        payOutBankStart: (state) => { state.payOutBank = getCommonStart()},
        payOutBankSuccess: (state, action) => { state.payOutBank = getCommonSuccess(action.payload)},
        payOutBankFailure: (state, action) => { state.payOutBank = getCommonFailure(action.payload)},
      
        importPayoutRequestStart: (state) => { state.importPayoutRequest = getCommonStart() },
        importPayoutRequestSuccess: (state, action) => { state.importPayoutRequest = getCommonSuccess(action.payload) },
        importPayoutRequestFailure: (state, action) => { state.importPayoutRequest = getCommonFailure(action.payload) },

        chatSupportMessageStart: (state) => { state.chatSupportMessage = getCommonStart() },
        chatSupportMessageSuccess: (state, action) => { state.chatSupportMessage = getCommonSuccess(action.payload) },
        chatSupportMessageFailure: (state, action) => { state.chatSupportMessage = getCommonFailure(action.payload) },

        chatSupportMessageReadStart: (state) => { state.chatSupportMessageRead = getCommonStart() },
        chatSupportMessageReadSuccess: (state, action) => { state.chatSupportMessageRead = getCommonSuccess(action.payload) },
        chatSupportMessageReadFailure: (state, action) => { state.chatSupportMessageRead = getCommonFailure(action.payload) },

        chatSupportUnreadListStart: (state) => { state.chatSupportUnreadList = getCommonStart() },
        chatSupportUnreadListSuccess: (state, action) => { state.chatSupportUnreadList = getCommonSuccess(action.payload) },
        chatSupportUnreadListFailure: (state, action) => { state.chatSupportUnreadList = getCommonFailure(action.payload) },

        chatSupportMessagesStart: (state) => { state.chatSupportMessages = getCommonStart() },
        chatSupportMessagesSuccess: (state, action) => { state.chatSupportMessages = getCommonSuccess(action.payload) },
        chatSupportMessagesFailure: (state, action) => { state.chatSupportMessages = getCommonFailure(action.payload) },

        setActiveChatSupport: (state, action) => { state.activeChatSupport = action.payload }

    },
});

export const {
    inrPayoutTransactionsListStart,
    inrPayoutTransactionsListSuccess,
    inrPayoutTransactionsListFailure,
    inrPayoutDetailsStart,
    inrPayoutDetailsSuccess,
    inrPayoutDetailsFailure,
    zyroPayoutBalanceStart,
    zyroPayoutBalanceSuccess,
    zyroPayoutBalanceFailure,
    zyroPayoutTransactionsStart,
    zyroPayoutTransactionsSuccess,
    zyroPayoutTransactionsFailure,
    requestZyroPayOutFailure,
    requestZyroPayOutStart,
    requestZyroPayOutSuccess,
    initiateDepositStart,
    initiateDepositSuccess,
    initiateDepositFailure,
    payoutDepositStart,
    payoutDepositSuccess,
    payoutDepositFailure,
    payoutDepositViewStart,
    payoutDepositViewSuccess,
    payoutDepositViewFailure,
    exportDepositStart,
    exportDepositSuccess,
    exportDepositFailure,
    initiateWithdrawalStart,
    initiateWithdrawalSuccess,
    initiateWithdrawalFailure,
    withdrawalTransactionsStart,
    withdrawalTransactionsSuccess,
    withdrawalTransactionsFailure,
    withdrawalTransactionsViewStart,
    withdrawalTransactionsViewSuccess,
    withdrawalTransactionsViewFailure,
    exportWithdrawalTransactionsStart,
    exportWithdrawalTransactionsSuccess,
    exportWithdrawalTransactionsFailure,
    walletStart,
    walletSuccess,
    walletFailure,
    payOutBankStart,
    payOutBankSuccess,
    payOutBankFailure,
    importPayoutRequestStart,
    importPayoutRequestSuccess,
    importPayoutRequestFailure,
    chatSupportMessageFailure,
    chatSupportMessageStart,
    chatSupportMessageSuccess,
    chatSupportMessageReadFailure,
    chatSupportMessageReadStart,
    chatSupportMessageReadSuccess,
    chatSupportUnreadListFailure,
    chatSupportUnreadListStart,
    chatSupportUnreadListSuccess,
    chatSupportMessagesFailure,
    chatSupportMessagesStart,
    chatSupportMessagesSuccess,
    setActiveChatSupport

} = PayoutSlice.actions;

export default PayoutSlice.reducer;