import React, { useEffect, useState } from "react";
import { Table, Modal, Image } from "react-bootstrap";
import FsLightbox from "fslightbox-react";
import { useTranslation } from "react-multi-lang";

const ViewPayoutModal = (props) => {

  const t = useTranslation("payout_transactions")

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "payment-initiated";
      case 3:
        return "payment-paid";
      case 2:
        return "payment-onhold";
      case 4:
        return "payment-cancelled";
      default:
        return "payment-initiated";
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="view-transaction-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("heading")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="view-transaction-table">
          <Table>
            <tbody>
              <tr>
                <td>{t("settlement_table.payment_id")}</td>
                <td>{props.show.order_id}</td>
              </tr>
              <tr>
                <td>{t("amount")}</td>
                <td>
                  <div className="transaction-amount">
                    {props.show.amount_formatted}
                  </div>
                </td>
              </tr>
              <tr>
                <td>{t("beneficiaryName.name")}</td>
                <td>{props.show.payout_bank.account_holder_name}</td>
              </tr>
              <tr>
                <td>{t("beneficiaryAccount.name")}</td>
                <td>{props.show.payout_bank.account_number}</td>
              </tr>
              <tr>
                <td>{t("beneficiaryAccount.bank")}</td>
                <td>{props.show.payout_bank.bank_name}</td>
              </tr>
              <tr>
                <td>{t("utrNumber")}</td>
                <td>{props.show.utr_number}</td>
              </tr>
              {props.show.payment_picture &&
              <tr>
                <td>{t("payment_picture")}</td>
                <td><a href={props.show.payment_picture} target="_blank">{t("view")}</a></td>
              </tr> 
}
              <tr>
                <td>{t("created_at")}</td>
                <td>{props.show.created_at}</td>
              </tr>
              <tr>
                <td>{t("status")}</td>
                <td style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div className={getStatusClass(props.show.status_formatted)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      fill="#5C9EEB"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0z"></path>
                    </svg>
                    {props.show.status_formatted}
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewPayoutModal;


